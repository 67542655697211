import moment from 'moment'

function roundDown(isoString, roundTo = 15) {
    const start = moment(isoString || undefined)


    const remainder = roundTo - (start.minute() % roundTo)

    return moment(start).subtract(roundTo - remainder, 'minutes').seconds(0).milliseconds(0).toISOString()
}

function roundUp(isoString, roundTo = 15) {
    const start = moment(isoString || undefined)

    const remainder = roundTo - (start.minute() % roundTo)

    return moment(start).add(remainder, 'minutes').seconds(0).milliseconds(0).toISOString()
}

export default {
    today: () => {
        return moment().format('YYYY-MM-DD')
    },
    yesterday: () => {
        return moment().subtract(1, 'day').format('YYYY-MM-DD')
    },
    tomorrow: () => {
        return moment().add(1, 'day').format('YYYY-MM-DD')
    },
    now: () => {
        return moment().format('HH:mm')
    },
    timeStamp: () => {
        return new Date().toISOString()
    },
    subtract: (...args) => {
        return moment().subtract(...args).format('YYYY-MM-DD')
    },
    diff: (start, end, accuracy = 'seconds') => {
        return moment(end).diff(moment(start), accuracy)
    },
    between: (startDate, endDate) => {
        startDate = moment(startDate).format('YYYY-MM-DD')
        endDate = moment(endDate).format('YYYY-MM-DD')


        if (endDate < startDate) {
            return []
        }

        if (endDate === startDate) {
            return [startDate]
        }

        const dates = [startDate]

        while (startDate < endDate) {
            startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
            dates.push(startDate)
        }

        return dates
    },
    roundDown,
    roundUp
}
