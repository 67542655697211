export default (number, minimumFractionDigits=0, maximumFractionDigits=2) => {
    if (typeof number === 'string') {
        number = parseFloat(number)
    }

    return number.toLocaleString('nl-NL', {
        minimumFractionDigits,
        maximumFractionDigits
    })
}
