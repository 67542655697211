import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import _ from 'underscore'

import SettingsStore from '../stores/SettingsStore.js'
import SettingsActions from '../actions/SettingsActions.js'

import NewPriceTable from '../components/priceTables/NewPriceTable.js'
import PriceTable from '../components/priceTables/PriceTable.js'
import NewSalaryTable from '../components/salaryTables/NewSalaryTable.js'
import SalaryTable from '../components/salaryTables/SalaryTable.js'
import NewMessageSet from '../components/messageSets/NewMessageSet.js'
import MessageSet from '../components/messageSets/MessageSet.js'
import formatNumber from '../utils/formatNumber.js'

import {Alert, Button, Card, ListGroup, Tabs, Tab} from 'react-bootstrap'
import FileSaver from 'file-saver'

class Settings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: 'priceTables',
            message: '',
            error: '',
            loading: false
        }

        this.store = SettingsStore
    }

    onClickSelectPriceTable(priceTable) {
        SettingsActions.selectPriceTable(priceTable)
    }

    onClickNewPriceTable() {
        SettingsActions.updatePriceTable(NewPriceTable())
    }


    onClickSelectSalaryTable(salaryTable) {
        SettingsActions.selectSalaryTable(salaryTable)
    }

    onClickNewSalaryTable() {
        SettingsActions.updateSalaryTable(NewSalaryTable())
    }


    onClickSelectMessageSet(messageSet) {
        SettingsActions.selectMessageSet(messageSet)
    }

    onClickNewMessageSet() {
        SettingsActions.updateMessageSet(NewMessageSet())
    }

    onClickExport(salaryTable) {
        const name = `${salaryTable.name}.csv`
        const content = []

        content.push(';Uurloon;Factor;Totaal')

        Object.keys(salaryTable.age).map((age) => {
            content.push(`${age}+`)

            salaryTable.age[age].map((row, index) => {
                content.push(`Trede ${index};${formatNumber(row.salary)};${formatNumber(row.factor, 0, 10)};${formatNumber(row.salary * row.factor)}`)
            })

            content.push('')
        })

        content.push('AOW+')

        salaryTable.aow.map((row, index) => {
            content.push(`Trede ${index};${formatNumber(row.salary)};${formatNumber(row.factor, 0, 10)};${formatNumber(row.salary * row.factor)}`)
        })

        content.push('')

        content.push(`ZZP loon;${formatNumber(salaryTable.zzpSalary)}`)

        const csv = content.join('\n')

        // utf-8 BOM to display € correctly - https://stackoverflow.com/questions/56154046/downloading-blob-with-type-text-csv-strips-unicode-bom
        const buffer = new ArrayBuffer(3)
        const dataView = new DataView(buffer)
        dataView.setUint8(0, 0xef)
        dataView.setUint8(1, 0xbb)
        dataView.setUint8(2, 0xbf)
        const read = new Uint8Array(buffer)

        const blob = new Blob([read, csv], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, name)
    }

    render() {
        const {tab, priceTables, priceTable, salaryTables, salaryTable, messageSets, messageSet, message, error} = this.state
        const {user} = this.props


        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: 20}}>
                <Card>
                    <Card.Body>
                        <Tabs activeKey={tab} onSelect={(tab) => this.setState({tab})} mountOnEnter unmountOnExit>
                            <Tab eventKey="priceTables" title="Tariefstaffels">

                                <div style={{display: 'flex', marginTop: 20}}>
                                    <div style={{width: 300}}>
                                        <ListGroup style={{margin: 20}}>
                                            {_.values(priceTables).map((priceTable) => {
                                                return (
                                                    <ListGroup.Item
                                                        key={priceTable._id} action
                                                        onClick={this.onClickSelectPriceTable.bind(this, priceTable)}
                                                    >
                                                        {priceTable.name}
                                                    </ListGroup.Item>)
                                            })}
                                        </ListGroup>

                                        {user?.type === 'Admin' &&
                                            <Button
                                                style={{marginLeft: 20}}
                                                onClick={this.onClickNewPriceTable.bind(this)}
                                            >
                                                Nieuwe Tariefstaffel
                                            </Button>
                                        }
                                    </div>

                                    <PriceTable user={user} priceTable={priceTable}/>
                                </div>
                            </Tab>

                            {user?.type === 'Admin' &&
                                <Tab eventKey="salaryTables" title="Personeelskostenstaffels">
                                    <div style={{display: 'flex', marginTop: 20}}>
                                        <div style={{width: 300}}>
                                            <ListGroup style={{margin: 20}}>
                                                {_.values(salaryTables).map((salaryTable) => {
                                                    return (
                                                        <ListGroup.Item
                                                            key={salaryTable._id} action
                                                            onClick={this.onClickSelectSalaryTable.bind(this, salaryTable)}
                                                        >
                                                            {salaryTable.name}
                                                        </ListGroup.Item>)
                                                })}
                                            </ListGroup>

                                            <Button
                                                style={{marginLeft: 20}}
                                                onClick={this.onClickNewSalaryTable.bind(this)}
                                            >
                                                Nieuwe Personeelskostenstaffel
                                            </Button>
                                        </div>

                                        <SalaryTable salaryTable={salaryTable} onClickExport={() => this.onClickExport(salaryTable)}/>
                                    </div>
                                </Tab>
                            }

                            {user?.type === 'Admin' &&
                                <Tab eventKey="messageSets" title="Berichtensets">
                                    <div style={{display: 'flex', marginTop: 20}}>
                                        <div style={{width: 300}}>
                                            <ListGroup style={{margin: 20}}>
                                                {_.values(messageSets).map((messageSet) => {
                                                    return (
                                                        <ListGroup.Item
                                                            key={messageSet._id} action
                                                            onClick={this.onClickSelectMessageSet.bind(this, messageSet)}
                                                        >
                                                            {messageSet.name}
                                                        </ListGroup.Item>)
                                                })}
                                            </ListGroup>

                                            <Button
                                                style={{marginLeft: 20}}
                                                onClick={this.onClickNewMessageSet.bind(this)}
                                            >
                                                Nieuwe Berichtenset
                                            </Button>
                                        </div>

                                        <MessageSet messageSet={messageSet}/>
                                    </div>
                                </Tab>
                            }
                        </Tabs>

                        <br/>
                        {message &&
                            <Alert variant='success'>{message}</Alert>
                        }
                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default Radium(Settings)
