import React from 'react'
import Reflux from 'reflux-react-16'

import {Form, Table as BootstrapTable} from 'react-bootstrap'
import Colors from './Colors.js'
import _ from 'underscore'

class Table extends Reflux.Component {
    onClickSelectAll() {
        const {filteredRows, selectedRows} = this.props
        const {setSelected} = this.props

        if (filteredRows.length === selectedRows.length) {
            setSelected([])
        } else {
            setSelected(_.pluck(filteredRows, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedRows = [...this.props.selectedRows]
        const {setSelected} = this.props

        const index = selectedRows.indexOf(id)

        if (index === -1) {
            selectedRows.push(id)
        } else {
            selectedRows.splice(index, 1)
        }

        setSelected(selectedRows)
    }

    render() {
        const {filteredRows, selectedRows, sortKey, sortReverse, changeSortKey, columns} = this.props

        return (
            <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                <BootstrapTable responsive >
                    <thead>
                        <tr>
                            <th>
                                <Form.Check
                                    checked={filteredRows.length && selectedRows.length === filteredRows.length}
                                    onChange={this.onClickSelectAll.bind(this)}
                                />
                            </th>

                            {columns.map((column) => {
                                return (
                                    <th
                                        key={column.key}
                                        style={{cursor: 'pointer', userSelect: 'none'}}
                                        onClick={() => changeSortKey(column.key)}
                                    >
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div>{column.title}</div>
                                            {column.key === sortKey && sortReverse &&
                                                <i className='mdi mdi-sort-descending'/>
                                            }
                                            {column.key === sortKey && !sortReverse &&
                                                <i className='mdi mdi-sort-ascending'/>
                                            }
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {filteredRows.map((stop, index) => {
                            return (
                                <tr key={stop._id}>
                                    <td>
                                        <Form.Check
                                            checked={selectedRows.indexOf(stop._id) > -1}
                                            onChange={this.onClickSelect.bind(this, stop._id)}
                                        />
                                    </td>
                                    {columns.map((column) => {
                                        return <td key={column.key}>{column.render(stop)}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BootstrapTable>
            </div>
        )
    }
}

export default Table
