import React from 'react'
import Radium from 'radium'
import {Button, Form, InputGroup} from 'react-bootstrap'


class SearchBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: '',
            operator: '=',
            text: ''
        }

        this.setFilter = this.setFilter.bind(this)
        this.selectOperator = this.selectOperator.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this)
        this.search = this.search.bind(this)
    }

    setFilter(event) {
        this.setState({filter: event.target.value})
    }

    selectOperator(event) {
        this.setState({operator: event.target.value})
    }

    changeSearchText(event) {
        this.setState({text: event.target.value})
    }

    search(event) {
        event.preventDefault()
        const {onChange, columns} = this.props
        const {filter, operator, text} = this.state

        const column = columns[filter] || {key: '', title: ''}
        const query = {key: column.key, title: column.title, operator, text}
        this.setState({filter: '', operator: '=', text: ''})
        onChange(query)
    }

    render() {
        const {filter, operator, text} = this.state
        const {columns} = this.props

        return (
            <Form onSubmit={this.search}>
                <div style={{display: 'flex'}}>
                    <div style={{width: 160}}>
                        <Form.Select
                            aria-label="Default select example"
                            id="filterType"
                            value={filter} onChange={this.setFilter}
                            style={{color: filter ? '#595959' : '#bbbbc3'}}
                        >
                            <option value="" disabled>Filter</option>
                            <option value=""/>
                            {columns.map((column, i) => {
                                if (column.title) {
                                    return (
                                        <option key={i} style={{color: '#404040'}} value={i}>
                                            {column.title}
                                        </option>
                                    )
                                }
                            })}
                        </Form.Select>
                    </div>

                    {filter &&
                        <div style={{width: 60, marginLeft: 5}}>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={this.selectOperator}
                                value={operator}
                            >
                                <option value='='>=</option>
                                <option value='!='>&#8800;</option>
                                <option value='<'>{'<'}</option>
                                <option value='>'>{'>'}</option>
                            </Form.Select>
                        </div>
                    }

                    <div style={{width: 200, marginLeft: 5}}>
                        <InputGroup>
                            <Form.Control
                                placeholder='Zoeken...'
                                value={text}
                                onChange={this.changeSearchText}
                            />

                            <Button type='submit'>
                                <i style={{fontSize: '0.9em'}} className='mdi mdi-magnify'/>
                            </Button>
                        </InputGroup>
                    </div>
                </div>
            </Form>
        )
    }
}

export default Radium(SearchBox)
