import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import moment from 'moment'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables)
import _ from 'underscore'


import {Card} from 'react-bootstrap'

class ChartCard extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            chart: null
        }

        this.drawChart = this.drawChart.bind(this)
    }

    componentDidMount() {
        const {data} = this.props
        this.drawChart(data)
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            const {chart} = this.state
            chart.destroy()
            const {data} = this.props
            this.drawChart(data)
        }
    }

    drawChart(data) {
        const ctx = this.chart.getContext('2d')

        // Chart.defaults.global.legend.display = false

        const labels = []
        const deliveredData = []
        const revenueData = []
        const costsData = []


        _.keys(data).sort().map((date) => {
            labels.push(moment(date).format('DD-MM-YYYY'))
            deliveredData.push(data[date].nrDelivered || 0)
            revenueData.push((data[date].revenue || 0).toFixed(2))
            costsData.push((data[date].costs || 0).toFixed(2))
        })

        const chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels,
                datasets: [
                    {
                        label: 'Aantal bezorgingen',
                        data: deliveredData,
                        yAxisID: 'yDeliveries'
                    },
                    {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(75, 192, 75, 0.8)',
                        label: 'Omzet',
                        data: revenueData,
                        type: 'line',
                        yAxisID: 'yCurrency'
                    },
                    {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(255, 99, 132, 0.8)',
                        label: 'Kosten',
                        data: costsData,
                        type: 'line',
                        yAxisID: 'yCurrency'
                    }
                ]
            },
            options: {
                scales: {
                    yDeliveries: {
                        ticks: {
                            beginAtZero: true
                        }
                    },
                    yCurrency: {
                        position: 'right',
                        ticks: {
                            beginAtZero: true
                        }
                    }
                }

            }
        })

        this.setState({chart})
    }

    render() {
        const {title, footer} = this.props

        return (

            <Card style={{width: '60vw'}}>
                <Card.Header>
                    <Card.Title>{title}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <canvas ref={(ref) => this.chart = ref}/>
                </Card.Body>
                {footer &&
                    <Card.Footer>{footer}</Card.Footer>
                }
            </Card>
        )
    }
}

export default Radium(ChartCard)
