import React from 'react'
import Radium from 'radium'
import _ from 'underscore'

import SettingsActions from '../../actions/SettingsActions.js'

import {Alert, Button, Card, Col, Form, Row, Tab, Tabs} from 'react-bootstrap'
import {DatePicker, Price} from '../forms/index.js'
import {IconButton, Popup} from '../UI/index.js'
import moment from 'moment'


class PriceTable extends React.Component {
    constructor(props) {
        super(props)

        this.state ={
            priceTable: JSON.parse(JSON.stringify((props.priceTable))),
            startDateIndex: props.priceTable?.dates.length-1,
            edit: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.priceTable !== prevProps.priceTable) {
            this.setState({
                priceTable: JSON.parse(JSON.stringify(this.props.priceTable)),
                startDateIndex: this.props.priceTable?.dates.length-1,
                message: '',
                error: ''
            })
        }
    }


    onChangeName(event) {
        const priceTable = JSON.parse(JSON.stringify(this.state.priceTable))
        priceTable.name = event.target.value
        this.setState({priceTable})
    }

    onSelectStartDateIndex(value) {
        if (value === 'new') {
            const priceTable = {...this.state.priceTable}

            const lastPriceTableInstance = JSON.parse(JSON.stringify(priceTable.dates[priceTable.dates.length-1]))
            lastPriceTableInstance.startDate = moment().format('YYYY-MM-DD')

            priceTable.dates.push(lastPriceTableInstance)

            this.setState({priceTable, startDateIndex: priceTable.dates.length-1})
        } else {
            this.setState({startDateIndex: parseInt(value)})
        }
    }

    onChangeStartDate(startDateIndex, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].startDate = event.target.value

        this.setState({priceTable})
    }

    onChangeQuantity(startDateIndex, index, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].quantities[index] = parseInt(event.target.value) || ''

        this.setState({priceTable})
    }


    onChangeRate(startDateIndex, zoneIndex, categoryIndex, rateIndex, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].zones[zoneIndex].categories[categoryIndex].rates[rateIndex] = parseFloat(event.target.value)


        this.setState({priceTable})
    }

    onChangeFee(startDateIndex, fee, index, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex][fee][index] = parseFloat(event.target.value)

        this.setState({priceTable})
    }

    onChangeCallOutFeeTill(startDateIndex, index, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].callOutFees[index].till = parseInt(event.target.value) || ''

        this.setState({priceTable})
    }

    onChangeCallOutFeePrice(startDateIndex, index, event) {
        const priceTable = {...this.state.priceTable}


        priceTable.dates[startDateIndex].callOutFees[index].price = parseFloat(event.target.value)

        this.setState({priceTable})
    }

    onClickAddCallOutFee(startDateIndex) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].callOutFees.push({till: 10, price: 0})

        this.setState({priceTable})
    }

    onClickRemoveCallOutFee(startDateIndex, index) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex].callOutFees.splice(index, 1)

        this.setState({priceTable})
    }


    onChangeValue(startDateIndex, key, event) {
        const priceTable = {...this.state.priceTable}

        priceTable.dates[startDateIndex][key] = parseFloat(event.target.value)

        this.setState({priceTable})
    }

    onChangeChecked(startDateIndex, key, event) {
        const priceTable = {...this.state.priceTable}
        priceTable.dates[startDateIndex][key] = event.target.checked

        this.setState({priceTable})
    }


    onClickSave() {
        const priceTable = {...this.state.priceTable}

        priceTable.dates.map((priceTableInstance) => {
            priceTableInstance.callOutFees = _.sortBy(priceTableInstance.callOutFees, 'till')
        })

        this.setState({message: '', error: ''})

        SettingsActions.updatePriceTable(priceTable, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({edit: false, message: 'Wijzigingen opgeslagen'})
            }
        })
    }

    onClickRemove() {
        const priceTable = {...this.state.priceTable}

        this.popup.open('Verwijder Tarieftabel', '', () => {
            SettingsActions.removePriceTable(priceTable._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {priceTable, startDateIndex, edit, message, error} = this.state
        const {user} = this.props

        if (!this.state.priceTable) {
            return null
        }

        const priceTableInstance = priceTable.dates[startDateIndex]


        return (
            <Card style={{marginTop: 20}}>
                <Card.Header>
                    <Card.Title>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {edit ?
                                <Form.Control style={{width: 300}} value={priceTable.name} onChange={this.onChangeName.bind(this)}/> :
                                <h5>{priceTable.name}</h5>
                            }
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body>

                    <Tabs activeKey={startDateIndex} onSelect={this.onSelectStartDateIndex.bind(this)} mountOnEnter unmountOnExit>
                        {priceTable.dates.map((priceTableInstance, index) => {
                            return (
                                <Tab key={index} eventKey={index} title={moment(priceTableInstance.startDate).format('MM-DD-YYYY')}/>
                            )
                        })}

                        {edit &&
                            <Tab eventKey='new' title='+'/>
                        }

                    </Tabs>
                    <br/>

                    <Form.Group className="mb-3">
                        <Form.Check
                            label='Progressieve staffels'
                            checked={!!priceTableInstance.newMethod}
                            onChange={this.onChangeChecked.bind(this, startDateIndex, 'newMethod')}
                            disabled={!edit}
                        />
                    </Form.Group>

                    {edit &&
                        <Row style={{marginBottom: 24, width: 200}}>
                            <Col>
                                <p>Startdatum</p>
                                <DatePicker
                                    style={{width: 200}}
                                    value={priceTableInstance.startDate}
                                    onChange={this.onChangeStartDate.bind(this, startDateIndex)}
                                />
                            </Col>
                        </Row>
                    }


                    <div style={{display: 'flex', marginLeft: 200}}>
                        {priceTableInstance.quantities.map((quantity, quantityIndex) => {
                            return (
                                <div key={`quantity${quantityIndex}`} style={{display: 'flex', alignItems: 'center', width: 125, marginLeft: 20}}>
                                    <h6>{`${priceTableInstance.quantities[quantityIndex - 1] + 1 || 0} t/m ${!edit ? quantity : ''}`}</h6>

                                    {edit &&
                                        <Form.Control
                                            style={{marginLeft: 5, width: 60}}
                                            type='number'
                                            value={quantity}
                                            onChange={this.onChangeQuantity.bind(this, startDateIndex, quantityIndex)}
                                        />
                                    }
                                </div>
                            )
                        })}

                        <div style={{display: 'flex', alignItems: 'center', width: 125, marginLeft: 20}}>
                            <h6>{`${priceTableInstance.quantities[priceTableInstance.quantities.length -1] + 1} t/m ∞`}</h6>
                        </div>
                    </div>

                    {Object.keys(priceTableInstance.zones).map((zoneName) => {
                        const zone = priceTableInstance.zones[zoneName]

                        return (
                            <div key={zoneName}>

                                <h6>{zoneName}</h6>


                                {Object.keys(zone.categories).map((categoryName) => {
                                    const category = zone.categories[categoryName]

                                    return (
                                        <div key={categoryName} style={{display: 'flex'}}>

                                            <p style={{width: 200}}>{categoryName}</p>


                                            {category.rates.map((rate, rateIndex) => {
                                                return (
                                                    <div key={`rate${rateIndex}`} style={{width: 125, marginLeft: 20}}>
                                                        {edit ?
                                                            <Price
                                                                value={category.rates[rateIndex] || 0}
                                                                onChange={this.onChangeRate.bind(this, startDateIndex, zoneName, categoryName, rateIndex)}
                                                            />:
                                                            <p>{`€ ${(category.rates[rateIndex] || 0).toFixed(2).replace('.', ',')}`}</p>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <br/>
                    <h6 style={{width: 200}}>Toeslagen</h6>
                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>XL-Pakket</p>

                        {priceTableInstance.xlFee.map((xlFee, index) => {
                            if (priceTableInstance.newMethod && index > 0) {
                                return
                            }

                            return (
                                <div key={`xlFee${index}`} style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={xlFee || 0}
                                            onChange={this.onChangeFee.bind(this, startDateIndex, 'xlFee', index)}
                                        />:
                                        <p>{`€ ${(xlFee || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Instelling/Bulk levering</p>

                        {priceTableInstance.bulkFee.map((bulkFee, index) => {
                            if (priceTableInstance.newMethod && index > 0) {
                                return
                            }

                            return (
                                <div key={`bulkFee${index}`} style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={bulkFee || 0}
                                            onChange={this.onChangeFee.bind(this, startDateIndex, 'bulkFee', index)}
                                        /> :
                                        <p>{`€ ${(bulkFee || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Tijdsafspraak</p>

                        {priceTableInstance.timeFee.map((timeFee, index) => {
                            if (priceTableInstance.newMethod && index > 0) {
                                return
                            }

                            return (
                                <div key={`timeFee${index}`} style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={timeFee || 0}
                                            onChange={this.onChangeFee.bind(this, startDateIndex, 'timeFee', index)}
                                        /> :
                                        <p>{`€ ${(timeFee || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Koelpakket</p>

                        {priceTableInstance.cooledFee.map((cooledFee, index) => {
                            if (priceTableInstance.newMethod && index > 0) {
                                return
                            }

                            return (
                                <div key={`cooledFee${index}`} style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={cooledFee || 0}
                                            onChange={this.onChangeFee.bind(this, startDateIndex, 'cooledFee', index)}
                                        /> :
                                        <p>{`€ ${(cooledFee || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Ophaalopdracht</p>

                        {priceTableInstance.pickupFee.map((pickupFee, index) => {
                            if (priceTableInstance.newMethod && index > 0) {
                                return
                            }

                            return (
                                <div key={`pickupFee${index}`} style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={pickupFee || 0}
                                            onChange={this.onChangeFee.bind(this, startDateIndex, 'pickupFee', index)}
                                        /> :
                                        <p>{`€ ${(pickupFee || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Late aanmelding</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.lateFee || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'lateFee')}
                                /> :
                                <p>{`€ ${(priceTableInstance.lateFee || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>


                    <br/>
                    <h6 style={{width: 200}}>Voorrijkosten</h6>

                    {priceTableInstance.callOutFees.map((callOutFee, index) => {
                        return (
                            <div key={`${index}callOutFee`} style={{display: 'flex'}}>
                                <p style={{width: 200}}>{`Minder dan ${callOutFee.till} stuks`}</p>

                                <div style={{marginLeft: 20}}>
                                    {edit ?
                                        <div style={{display: 'flex'}}>
                                            <Form.Control
                                                style={{width: 60}}
                                                type='number'
                                                value={callOutFee.till}
                                                onChange={this.onChangeCallOutFeeTill.bind(this, startDateIndex, index)}
                                            />
                                            <Price
                                                style={{marginLeft: 5, width: 100}}
                                                value={callOutFee.price || 0}
                                                onChange={this.onChangeCallOutFeePrice.bind(this, startDateIndex, index)}
                                            />

                                            {!!index &&
                                                <IconButton onClick={this.onClickRemoveCallOutFee.bind(this, startDateIndex, index)}>
                                                    <i className='mdi mdi-delete'/>
                                                </IconButton>
                                            }
                                        </div> :
                                        <p>{`€ ${(callOutFee.price || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    {edit &&
                        <IconButton onClick={this.onClickAddCallOutFee.bind(this, startDateIndex)}>
                            <i className='mdi mdi-plus'/>
                        </IconButton>
                    }

                    <br/>
                    <h6 style={{width: 200}}>Overig</h6>
                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Uurtarief fietskoerier</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.hourPriceBike || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'hourPriceBike')}
                                />:
                                <p>{`€ ${(priceTableInstance.hourPriceBike || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Uurtarief autobezorging</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.hourPriceCar || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'hourPriceCar')}
                                />:
                                <p>{`€ ${(priceTableInstance.hourPriceCar || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Vergoeding per km</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.distanceFee || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'distanceFee')}
                                />:
                                <p>{`€ ${(priceTableInstance.distanceFee || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Toeslag koeltransport</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.cooledTransportFee || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'cooledTransportFee')}
                                />:
                                <p>{`€ ${(priceTableInstance.cooledTransportFee || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Wachttijd per minuut</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.waitTime || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'waitTime')}
                                />:
                                <p>{`€ ${(priceTableInstance.waitTime || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Extra apotheek</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.extraPharmacy || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'extraPharmacy')}
                                />:
                                <p>{`€ ${(priceTableInstance.extraPharmacy || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Minimum per dag</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTableInstance.minimumPerDay || 0}
                                    onChange={this.onChangeValue.bind(this, startDateIndex, 'minimumPerDay')}
                                />:
                                <p>{`€ ${(priceTableInstance.minimumPerDay || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>


                    {message &&
                        <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                    }
                </Card.Body>

                {user?.type === 'Admin' &&
                    <Card.Footer>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            {edit ?
                                <Button onClick={this.onClickSave.bind(this)}>Opslaan</Button> :
                                <Button onClick={() => this.setState({edit: true, message: ''})}>Wijzigen</Button>
                            }

                            {false &&
                            <Button variant='danger' onClick={this.onClickRemove.bind(this)} >
                                Verwijder
                            </Button>
                            }
                        </div>
                    </Card.Footer>
                }

                <Popup ref={(ref) => this.popup = ref}/>
            </Card>
        )
    }
}

export default Radium(PriceTable)
