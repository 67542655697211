import moment from 'moment'

export default () => {
    return JSON.parse(JSON.stringify(
        {
            name: 'Nieuwe tariefstaffel',
            dates: [{
                newMethod: true,
                startDate: moment().format('YYYY-MM-DD'),
                quantities: [60, 80, 110, 140, 170],
                zones: {
                    'Zone 1': {
                        categories: {
                            Aanbelpakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            },
                            Brievenbuspakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            }
                        }
                    },
                    'Zone 2': {
                        categories: {
                            Aanbelpakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            },
                            Brievenbuspakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            }
                        }
                    },
                    'Zone 3': {
                        categories: {
                            Aanbelpakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            },
                            Brievenbuspakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            }
                        }
                    },
                    'Zone 4': {
                        categories: {
                            Aanbelpakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            },
                            Brievenbuspakket: {
                                rates: [0, 0, 0, 0, 0, 0]
                            }
                        }
                    }
                },

                xlFee: [0, 0, 0, 0, 0, 0],
                bulkFee: [0, 0, 0, 0, 0, 0],
                timeFee: [0, 0, 0, 0, 0, 0],
                cooledFee: [0, 0, 0, 0, 0, 0],
                pickupFee: [0, 0, 0, 0, 0, 0],

                lateFee: 0,
                callOutFees: [{till: 10, price: 0}],
                hourPriceBike: 0,
                hourPriceCar: 0,
                distanceFee: 0,
                cooledTransportFee: 0,
                waitTime: 0,
                extraPharmacy: 0,
                minimumPerDay: 0
            }]
        }
    ))
}
