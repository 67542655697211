import React from 'react'
import Reflux from 'reflux-react-16'
import {Card} from 'react-bootstrap'

import StopStore from '../stores/StopStore.js'
import StopActions from '../actions/StopActions.js'
import moment from 'moment'


class TrackTrace extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            err: null,
            stop: {}
        }

        this.store = StopStore
    }

    componentDidMount() {
        const {barcode, postalCode} = this.props.match.params

        if (barcode && postalCode) {
            StopActions.findByBarcode(barcode, postalCode, (err, stop) => {
                this.setState({err, stop})
            })
        }
    }

    render() {
        const {stop} = this.state

        // if (stop.status === 'pending') {
        //     stop.status = 'accepted'
        // }


        // if (stop.customerName === 'Testapotheek van Luuk') {
        //     stop.customerName = 'Apotheek'

        //     stop.messengerName = stop.messengerName || 'Marc'
        //     stop.messengerPicture = stop.messengerPicture || '/images/fietskoerier.jpg'

        //     stop.status = ['pending', 'accepted'].includes(stop.status) ? 'out for delivery' : stop.status
        //     stop.eta = stop.eta || moment().add(1, 'hour').format('HH:mm')
        // }


        const startTime = moment(stop?.eta, 'HH:mm').subtract(15, 'minutes').format('HH:mm')
        const endTime = moment(stop?.eta, 'HH:mm').add(45, 'minutes').format('HH:mm')

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh'}}>
                <div style={{paddingLeft: 16, width: '100%', maxWidth: 1280, height: 80}}>
                    <img style={{objectFit: 'contain', width: 200, marginTop: 20}}src='/images/logo.svg'/>
                </div>

                <img
                    style={{
                        width: '100%',
                        height: '50vw',
                        maxHeight: 500,
                        objectFit: 'cover',
                        objectPosition: 'center 20%',
                        borderRadius: '0px 0px'
                    }}
                    src='/images/track_trace_background.jpg'
                />

                <div style={{width: 800, maxWidth: '95%', marginTop: -100}}>
                    <Card style={{width: '100%', borderRadius: 25}}>
                        <Card.Body style={{padding: 40}}>

                            <h6 style={{fontSize: 22, fontWeight: 300, color: '#009640'}}>
                                {stop.status === 'delivered' ?
                                    'Uw medicijnen zijn bezorgd' :
                                    stop.status === 'out for delivery' ?
                                        'Uw medicijnen zijn onderweg' :
                                        'Uw medicijnen zijn aangemeld voor bezorging'
                                }
                            </h6>

                            <h3 style={{fontWeight: 600}}>
                                {stop.status === 'delivered' ?
                                    `Bezorgd op ${moment(stop.timeOfArrival).locale('NL-nl').format('dddd D MMMM')} om ${moment(stop.timeOfArrival).format('HH:mm')}`:
                                    stop.eta ?
                                        `Bezorgtijd: vandaag tussen ${startTime} en ${endTime}` :
                                        'Bezorgtijd: nog niet bekend'
                                }
                            </h3>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
                                <StatusCircle
                                    icon='mdi mdi-pill'
                                    active={true}
                                    currentStatus={stop.status === 'accepted' || stop.status === 'planned'}

                                />

                                <StatusBar
                                    active={stop.status === 'out for delivery' || stop.status === 'delivered'}
                                />

                                <StatusCircle
                                    icon='mdi mdi-bike-fast'
                                    active={stop.status === 'out for delivery' || stop.status === 'delivered'}
                                    currentStatus={stop.status === 'out for delivery'}
                                />

                                <StatusBar
                                    active={stop.status === 'delivered'}
                                />

                                <StatusCircle
                                    icon='mdi mdi-home-outline'
                                    active={stop.status === 'delivered'}
                                    currentStatus={stop.status === 'delivered'}
                                />
                            </div>
                        </Card.Body>
                    </Card>

                    {stop.messengerName &&
                        <>
                            <h3 style={{fontWeight: 600, marginTop: 45, marginBottom: 30}}>Wie bezorgd uw medicijnen?</h3>
                            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: 15, marginBottom: 60}}>
                                <h6 style={{fontSize: 22, fontWeight: 300, flex: 1}}>
                                    {`Uw medicijnen worden bezorgd door ${stop.messengerName}`}
                                </h6>

                                {stop.messengerPicture &&
                                    <img src={stop.messengerPicture} style={{height: 150, width: 150, borderRadius: '50%', objectFit: 'cover'}}/>
                                }
                            </div>
                        </>
                    }


                    <h3 style={{fontWeight: 600, marginTop: 45, marginBottom: 30}}>Waar worden uw medicijnen bezorgd?</h3>

                    <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 15, marginBottom: 60}}>
                        <div style={{flex: 1, minWidth: 300, marginBottom: 30}}>
                            <h6 style={{fontSize: 22, fontWeight: 600, color: '#009640'}}>Bezorgadres</h6>
                            <div style={{fontWeight: 500}}>{stop.name || ''}</div>
                            <div>{`${stop.street || ''} ${stop.houseNumber || ''}${stop.houseNumberAddition || ''}`}</div>
                            <div>{`${stop.postalCode || ''} ${stop.city || ''}`}</div>
                        </div>

                        <div style={{flex: 1, minWidth: 300}}>
                            <h6 style={{fontSize: 22, fontWeight: 600, color: '#009640'}}>Afzender</h6>
                            <div style={{fontWeight: 500}}>{stop.customerName || ''}</div>
                            <div>{`${stop.customerAddress?.street || ''} ${stop.customerAddress?.nr || ''}${stop.customerAddress?.addition || ''}`}</div>
                            <div>{`${stop.customerAddress?.postalCode || ''} ${stop.customerAddress?.city || ''}`}</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const StatusCircle = ({icon, active, currentStatus}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: currentStatus ? 90 : 70,
            width: currentStatus ? 90 : 70,
            borderRadius: '50%',
            color: 'white',
            fontSize: currentStatus ? 48 : 32,
            background: '#007C9A',
            opacity: active ? 1 : 0.4
        }}
        >
            <i className={icon}/>
        </div>
    )
}

const StatusBar = ({active}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 5,
            flex: 1,
            background: '#007C9A',
            opacity: active ? 1 : 0.4
        }}
        />
    )
}


export default (TrackTrace)
