import React from 'react'
import Radium from 'radium'
import Reflux from 'reflux-react-16'

import LoginStore from '../stores/LoginStore.js'
import LoginActions from '../actions/LoginActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import Spinner from 'react-spinkit'
import {Colors} from '../components/UI/index.js'


class ForgotPassword extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {email: '', message: '', error: '', loading: false}
        this.store = LoginStore
    }

    onSubmit(event) {
        event.preventDefault()
        const {email} = this.state
        this.setState({message: '', error: '', loading: true})

        LoginActions.sendResetPasswordMail(email, (err) => {
            if (err) {
                this.setState({message: '', error: err, loading: false})
            } else {
                this.setState({message: 'Indien je emailadres bij ons bekend is, is er een mail gestuurd om je wachtwoord te wijzigen.', error: '', loading: false})
            }
        })
    }

    render() {
        const {email, message, error, loading} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', background: Colors.lightGrey}}>

                <img style={{width: '90%', maxWidth: 450, marginTop: 100, objectFit: 'contain'}} src="/images/logo.png"/>

                <Card style={{width: '90%', maxWidth: 450, marginTop: 20}}>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                        <Card.Header>
                            <Card.Title>Wachtwoord vergeten</Card.Title>
                        </Card.Header>

                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder='Emailadres'
                                    value={email}
                                    onChange={(event) => this.setState({email: event.target.value})}
                                />
                            </Form.Group>


                            {message &&
                                <Alert variant='success'>{message}</Alert>
                            }
                            {error &&
                                <Alert variant='danger'>{error}</Alert>
                            }
                        </Card.Body>

                        <Card.Footer>
                            <Button type="submit" disabled={loading}>
                                {loading ?
                                    <Spinner fadeIn='none'/> :
                                    'Bevestigen'
                                }
                            </Button>
                            <Button variant='link' href='/koerier/login'>Inloggen</Button>
                        </Card.Footer>
                    </Form>
                </Card>
            </div>
        )
    }
}

export default Radium(ForgotPassword)
