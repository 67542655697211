const primary = '#00AAFF'
const secondary = '#6c757d'
const success = '#00AF3F'
const info = '#17a2b8'
const warning = '#ffc107'
const danger = '#dc3545'
const light = '#fff'
const lightGrey = '#f2f2f2'
const grey = '#dee2e6'
const darkGrey = '#737373'
const dark = '#343a40'
const textGrey = 'rgba(255, 255, 255, .5)'

const zoneColors = {
    1: primary,
    2: success,
    3: warning,
    4: danger

}

export default {
    primary,
    secondary,
    success,
    info,
    warning,
    danger,
    light,
    lightGrey,
    grey,
    darkGrey,
    dark,
    textGrey,

    zoneColors
}
