export default () => {
    return JSON.parse(JSON.stringify(
        {
            active: true,
            groupId: '',
            ais: '',
            aisId: '',
            region: 'Landelijk',
            teamLeader: '',
            name: '',
            address: {
                name: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL'
            },
            email: '',
            phone: '',
            priceTable: '',
            messageSet: '',
            extraPharmacy: false,
            minimumPerDay: false,
            callOutFee: false,
            invoiceHours: false,
            distanceFee: false,
            zone1Distance: 0,
            zone2Distance: 0,
            zone3Distance: 0,
            zone4Distance: 0,
            useCutoffLateFee: false,
            cutoffLateFee: '00:00',
            useCutoffSameDay: false,
            cutoffSameDay: '00:00'
        }
    ))
}
