import Reflux from 'reflux-react-16'
import moment from 'moment'
import socket from '../../socketio/socketio.js'

import ResultActions from '../actions/ResultActions.js'

export default class ResultStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            customerId: '',
            coordinatorId: '',
            regionId: '',
            userId: ''
        }

        this.listenables = ResultActions
    }


    onSetStartDate(startDate) {
        this.setState({startDate})
    }

    onSetEndDate(endDate) {
        this.setState({endDate})
    }

    onSetCustomer(customerId) {
        this.setState({customerId})
    }


    onSetCoordinator(coordinatorId) {
        this.setState({coordinatorId})
    }

    onSetRegion(regionId) {
        this.setState({regionId})
    }

    onSetUser(userId) {
        this.setState({userId})
    }


    onGetStatistics(...args) {
        const {startDate, endDate} = this.state
        socket.emit('results.getStatistics', sessionStorage.token, startDate, endDate, ...args)
    }


    onGetCustomerRapportCSV(...args) {
        const {customerId, startDate, endDate} = this.state
        socket.emit('results.getCustomerRapportCSV', sessionStorage.token, customerId, startDate, endDate, ...args)
    }

    onGetCustomerResultCSV(...args) {
        const {customerId, startDate, endDate} = this.state
        socket.emit('results.getCustomerResultCSV', sessionStorage.token, customerId, startDate, endDate, ...args)
    }

    onGetCustomerSpecificationCSV(...args) {
        const {customerId, startDate, endDate} = this.state
        socket.emit('results.getCustomerSpecificationCSV', sessionStorage.token, customerId, startDate, endDate, ...args)
    }

    onGetCustomerSpecificationPDF(...args) {
        const {customerId, startDate, endDate} = this.state
        socket.emit('results.getCustomerSpecificationPDF', sessionStorage.token, customerId, startDate, endDate, ...args)
    }

    onGetCoordinatorRapportCSV(...args) {
        const {coordinatorId, startDate, endDate} = this.state
        socket.emit('results.getCoordinatorRapportCSV', sessionStorage.token, coordinatorId, startDate, endDate, ...args)
    }

    onGetRegionRapportCSV(...args) {
        const {regionId, startDate, endDate} = this.state
        socket.emit('results.getRegionRapportCSV', sessionStorage.token, regionId, startDate, endDate, ...args)
    }


    onGetUserResultCSV(...args) {
        const {userId, startDate, endDate} = this.state
        socket.emit('results.getUserResultCSV', sessionStorage.token, userId, startDate, endDate, ...args)
    }

    onGetUserSalaryCSV(...args) {
        const {userId, startDate, endDate} = this.state
        socket.emit('results.getUserSalaryCSV', sessionStorage.token, userId, startDate, endDate, ...args)
    }

    onGetRevenueCSV(...args) {
        const {startDate, endDate} = this.state
        socket.emit('results.getRevenueCSV', sessionStorage.token, startDate, endDate, ...args)
    }
}
