import React from 'react'
import Radium from 'radium'

import {Form, InputGroup} from 'react-bootstrap'

class Multiply extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value.toString().replace('.', ',')
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value.toString().replace('.', ',')})
        }
    }

    onChangeInput(event) {
        const {onChange} = this.props

        const value = event.target.value.replace('.', ',')

        if (/^$|^[0-9]{0,4}[,]?[0-9]*$/.test(value)) {
            this.setState({value})

            if (/^[0]{1}$|^[0]{1},[0-9]*$|^[1-9]{1}[0-9]{0,3}$|^[1-9]{1}[0-9]{0,3},[0-9]*$/.test(value)) {
                event.target.value = value.replace(',', '.')
                onChange(event)
            }
        }
    }

    render() {
        const {disabled, style, plaintext, readOnly} = this.props
        const {value} = this.state

        return (
            <div style={style}>
                <InputGroup>
                    {!plaintext &&
                        <InputGroup.Text>
                            <i style={{fontSize: '0.9em'}} className='mdi mdi-close'/>
                        </InputGroup.Text>
                    }

                    <Form.Control
                        disabled={disabled}
                        plaintext={plaintext}
                        readOnly={readOnly}
                        value={plaintext ? `x ${parseFloat(value.replace(',', '.') || 0).toString().replace('.', ',')}`: value}
                        onChange={this.onChangeInput.bind(this)}
                        ref={(ref) => this.formControl = ref}
                    />
                </InputGroup>
            </div>
        )
    }
}

export default Radium(Multiply)
