import Reflux from 'reflux-react-16'
import _ from 'underscore'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import UserActions from '../actions/UserActions.js'

export default class UserStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            users: {},
            filteredUsers: {},
            selectedUsers: [],
            usersLoading: false,
            usersSortKey: 'name',
            usersSortReverse: false,
            userQueries: []
        }

        this.listenables = UserActions

        UserActions.get(true)

        socket.on('updateUsers', () => {
            UserActions.get()
        })
    }

    onChangeQueries(userQueries) {
        const {users} = this.state

        const filteredUsers = filter.users(users, userQueries)

        this.setState({filteredUsers, userQueries})
    }

    onChangeSortKey(newSortKey) {
        let {filteredUsers, usersSortKey, usersSortReverse} = this.state

        if (usersSortKey === newSortKey) {
            usersSortReverse = !usersSortReverse
        } else {
            usersSortReverse = false
        }

        usersSortKey = newSortKey

        filteredUsers = _.sortBy(filteredUsers, usersSortKey)

        if (usersSortReverse) {
            filteredUsers.reverse()
        }

        this.setState({filteredUsers, usersSortKey, usersSortReverse})
    }

    onSetSelected(selectedUsers) {
        this.setState({selectedUsers})
    }

    onGet(loading) {
        this.setState({usersLoading: !!loading})

        socket.emit('users.get', sessionStorage.token, (err, users) => {
            if (!err) {
                const {userQueries, usersSortKey, usersSortReverse} = this.state

                let filteredUsers = filter.users(users, userQueries)

                filteredUsers = _.sortBy(filteredUsers, usersSortKey)
                if (usersSortReverse) {
                    filteredUsers.reverse()
                }

                this.setState({users, filteredUsers, usersLoading: false})
            }
        })
    }

    onUpdate(user, callback) {
        socket.emit('users.update', sessionStorage.token, user, callback)
    }

    onRemove(id, callback) {
        socket.emit('users.remove', sessionStorage.token, id, callback)
    }

    onSendWelcomeMail(email, callback) {
        socket.emit('user.sendWelcomeMail', sessionStorage.token, email, callback)
    }
}
