import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setDate',
    'changeQueries',
    'changeSortKey',
    'setSelected',
    'get',
    'findByBarcode',
    'importStops'
])
