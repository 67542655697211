import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setStartDate',
    'setEndDate',
    'setCustomer',
    'setCoordinator',
    'setRegion',
    'setUser',
    'getStatistics',
    'getCustomerRapportCSV',
    'getCustomerResultCSV',
    'getCustomerSpecificationCSV',
    'getCustomerSpecificationPDF',
    'getCoordinatorRapportCSV',
    'getRegionRapportCSV',
    'getUserResultCSV',
    'getUserSalaryCSV',
    'getRevenueCSV'
])
