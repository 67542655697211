import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import moment from 'moment'
import time from '../../../server/utils/time.js'

import StopStore from '../stores/StopStore.js'
import StopActions from '../actions/StopActions.js'
import CustomerStore from '../stores/CustomerStore.js'
import UserStore from '../stores/UserStore.js'

import {SearchBar, Popup, Table} from '../components/UI/index.js'
import {DatePicker} from '../components/forms/index.js'
import StopModal from '../components/stops/StopModal.js'
import {Button} from 'react-bootstrap'
import FileSaver from 'file-saver'
import _ from 'underscore'

class Stops extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [StopStore, CustomerStore, UserStore]
    }

    onClickTrackTrace(stop) {
        window.open(`/${stop.barcode}/${stop.postalCode}`)
    }

    columns() {
        return [
            {title: 'Datum', key: 'date', render: (stop) => moment(stop.date, 'YYYY-MM-DD').format('DD-MM-YYYY')},
            {title: 'Klant', key: 'customerName', render: (stop) => stop.customerName},
            {title: 'Adres', key: 'address', render: (stop) => `${stop.street} ${stop.houseNumber}${stop.houseNumberAddition}, ${stop.postalCode} ${stop.city}`},
            {title: 'Barcode', key: 'barcode', render: (stop) => stop.barcode},
            {title: 'Pakkettype', key: 'type', render: (stop) => stop.type || 'Onbekend'},
            {title: 'Status', key: 'status', render: (stop) => stop.status},
            {title: '', key: '', render: (stop) => {
                return (
                    <div style={{display: 'flex'}}>
                        <Button size='sm' style={{marginRight: 10}} onClick={this.onClickTrackTrace.bind(this, stop)}>
                            <i className='mdi mdi-barcode-scan'/>
                        </Button>

                        <Button size='sm' onClick={this.onClickView.bind(this, stop)}>
                            <i className='mdi mdi-eye'/>
                        </Button>

                    </div>
                )
            }}
        ]
    }

    onClickView(stop) {
        this.stopModal.open(stop)
        StopActions.setSelected([])
    }

    onClickImport() {
        this.fileUpload.click()
    }

    importStops(event) {
        event.preventDefault()
        const reader = new FileReader()


        reader.onloadend = (event) => {
            this.fileUpload.value = '' // Reset input value
            const lines = event.target.result.toString().split('\n')

            const stops = []

            for (const line of lines) {
                const columns = line.split(';')

                const stop = {
                    customerIdentifier: columns[0]?.trim() || '',
                    ais: '',
                    aisId: '',
                    barcode: columns[1]?.trim() || Math.random().toString().slice(2, 10),
                    date: time.today(),
                    status: 'pending',
                    name: columns[2]?.trim() || '',
                    street: columns[3]?.trim() || '',
                    houseNumber: columns[4]?.trim() || '',
                    houseNumberAddition: columns[5]?.trim() || '',
                    postalCode: columns[6]?.trim() || '',
                    city: columns[7]?.trim() || '',
                    country: 'NL',
                    deliveryInstructions: columns[8]?.trim() || '',
                    phone: columns[9]?.trim() || '',
                    email: columns[10]?.trim() || ''
                }

                if (stop.customerIdentifier && stop.customerIdentifier !== 'aisId' && stop.name && stop.street && stop.houseNumber && stop.postalCode && stop.city) {
                    stops.push(stop)
                }
            }

            this.popup.open('Importeren', `${stops.length} stops worden geimporteerd`, () => {
                StopActions.importStops(stops, (err, errors) => {
                    if (err) {
                        this.popup.setError(err)
                    } else if (errors.length) {
                        this.popup.setMessage(`${stops.length - errors.length} stops geimporteerd`)
                        this.popup.setErrors(errors)
                    } else {
                        this.popup.setMessage(`${stops.length} stops geimporteerd`)
                    }
                })
            })
        }

        reader.readAsText(event.target.files[0])
    }

    onClickExport() {
        const {stops, selectedStops} = this.state
        const exportStops = []

        selectedStops.map((id) => {
            const customer = _.find(stops, (customer) => customer._id === id)

            if (stops) {
                exportStops.push(customer)
            }
        })

        const name = 'Stops.csv'
        const content = [this.columns().filter((column) => column.key).map((column) => column.title).join(';')]

        exportStops.map((stop) => {
            const line = []

            this.columns().filter((column) => column.key).map((column) => {
                if (column.renderText) {
                    line.push(column.renderText(stop))
                } else {
                    line.push(column.render(stop))
                }
            })

            content.push(line.join(';'))
        })

        const csv = content.join('\n')

        // utf-8 BOM to display € correctly - https://stackoverflow.com/questions/56154046/downloading-blob-with-type-text-csv-strips-unicode-bom
        const buffer = new ArrayBuffer(3)
        const dataView = new DataView(buffer)
        dataView.setUint8(0, 0xef)
        dataView.setUint8(1, 0xbb)
        dataView.setUint8(2, 0xbf)
        const read = new Uint8Array(buffer)

        const blob = new Blob([read, csv], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, name)


        StopActions.setSelected([])
    }

    render() {
        const {filteredStops, selectedStops, date, stopQueries, stopsSortKey, stopsSortReverse, customers, users} = this.state
        const {user} = this.props

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <DatePicker
                        style={{width: 190, marginRight: 10}}
                        value={date}
                        onChange={(event) => StopActions.setDate(event.target.value)}
                    />

                    <SearchBar
                        columns={this.columns()}
                        queries={stopQueries || []}
                        onChange={StopActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        <Button
                            style={{marginLeft: 10}}
                            onClick={this.onClickImport.bind(this)}
                        >
                            Importeren
                        </Button>

                        {selectedStops.length > 0 &&
                        <>
                            {user?.type === 'Admin' &&
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={this.onClickExport.bind(this)}
                                >
                                    CSV Export
                                </Button>
                            }
                        </>
                        }
                    </div>
                </div>

                <Table
                    filteredRows={filteredStops}
                    selectedRows={selectedStops}
                    sortKey={stopsSortKey}
                    sortReverse={stopsSortReverse}
                    setSelected={StopActions.setSelected}
                    changeSortKey={StopActions.changeSortKey}
                    columns={this.columns()}
                />

                <StopModal
                    customers={customers}
                    users={users}
                    ref={(ref) => this.stopModal = ref}
                />

                <Popup ref={(ref) => this.popup = ref}/>

                <input
                    style={{display: 'none'}}
                    type="file" accept=".csv"
                    ref={(ref) => this.fileUpload = ref}
                    onChange={this.importStops.bind(this)}
                />
            </div>
        )
    }
}

export default Radium(Stops)
