import React from 'react'
import Radium from 'radium'

import SettingsActions from '../../actions/SettingsActions.js'

import {Alert, Button, Card, Col, Form, Row, Tab, Tabs} from 'react-bootstrap'
import {DatePicker, Multiply, Price} from '../forms/index.js'
import {Popup} from '../UI/index.js'
import _ from 'lodash'
import moment from 'moment'


class SalaryTable extends React.Component {
    constructor(props) {
        super(props)

        this.state ={
            salaryTable: JSON.parse(JSON.stringify((props.salaryTable))),
            startDateIndex: props.salaryTable?.dates.length-1,
            edit: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.salaryTable) !== JSON.stringify(prevProps.salaryTable)) {
            this.setState(({
                salaryTable: JSON.parse(JSON.stringify(this.props.salaryTable)),
                startDateIndex: this.props.salaryTable?.dates.length-1,
                message: '',
                error: ''
            }))
        }
    }

    onSelectStartDateIndex(value) {
        if (value === 'new') {
            const salaryTable = {...this.state.salaryTable}

            const lastSalaryTableInstance = JSON.parse(JSON.stringify(salaryTable.dates[salaryTable.dates.length-1]))
            lastSalaryTableInstance.startDate = moment().format('YYYY-MM-DD')

            salaryTable.dates.push(lastSalaryTableInstance)

            this.setState({salaryTable, startDateIndex: salaryTable.dates.length-1})
        } else {
            this.setState({startDateIndex: parseInt(value)})
        }
    }

    onChangeValue(path, parser, event) {
        const salaryTable = {...this.state.salaryTable}

        if (typeof parser === 'function') {
            _.set(salaryTable, path, parser(event.target.value))
        } else {
            event = parser
            _.set(salaryTable, path, event.target.value)
        }

        this.setState({salaryTable})
    }

    onClickSave() {
        const {salaryTable} = this.state

        this.setState({message: '', error: ''})

        SettingsActions.updateSalaryTable(salaryTable, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({edit: false, message: 'Wijzigingen opgeslagen'})
            }
        })
    }

    onClickRemove() {
        const {salaryTable} = this.state

        this.popup.open('Verwijder Loontabel', '', () => {
            SettingsActions.removeSalaryTable(salaryTable._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {salaryTable, startDateIndex, edit, message, error} = this.state

        if (!salaryTable) {
            return null
        }


        const salaryTableInstance = salaryTable.dates[startDateIndex]

        return (
            <Card style={{marginTop: 20}}>
                <Card.Header>
                    <Card.Title>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {edit ?
                                <Form.Control
                                    style={{width: 300}}
                                    value={salaryTable.name}
                                    onChange={this.onChangeValue.bind(this, 'name')}
                                /> :
                                <h5>{salaryTable.name}</h5>
                            }
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <Tabs activeKey={startDateIndex} onSelect={this.onSelectStartDateIndex.bind(this)} mountOnEnter unmountOnExit>
                        {salaryTable.dates.map((salaryTableInstance, index) => {
                            return (
                                <Tab key={index} eventKey={index} title={moment(salaryTableInstance.startDate).format('MM-DD-YYYY')}/>
                            )
                        })}

                        {edit &&
                            <Tab eventKey='new' title='+'/>
                        }

                    </Tabs>
                    <br/>

                    {edit &&
                        <Row style={{marginBottom: 24, width: 200}}>
                            <Col>
                                <p>Startdatum</p>
                                <DatePicker
                                    style={{width: 200}}
                                    value={salaryTableInstance.startDate}
                                    onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.startDate`)}
                                />
                            </Col>
                        </Row>
                    }

                    <div style={{display: 'flex'}}>
                        <h6 style={{width: 100}}></h6>
                        <h6 style={{width: 125, marginLeft: 20}}>Uurloon</h6>
                        <h6 style={{width: 200, marginLeft: 20}}>Factor</h6>
                        <h6 style={{width: 125, marginLeft: 20}}>Totaal</h6>
                    </div>

                    {_.keys(salaryTableInstance.age).map((age) => {
                        return (
                            <div key={`age${age}`} style={{marginBottom: 15}}>

                                <h6 style={{width: 100}}>{`${age}+`}</h6>


                                {salaryTableInstance.age[age].map((trede, index) => {
                                    return (
                                        <div key={`trede${index}`} style={{display: 'flex'}}>

                                            <p style={{width: 100}}>{`Trede ${index}`}</p>

                                            <div style={{width: 125, marginLeft: 20}}>

                                                <Price
                                                    value={trede.salary || 0}
                                                    onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.age.${age}.${index}.salary`, parseFloat)}
                                                    plaintext={!edit}
                                                    readOnly={!edit}
                                                />
                                            </div>

                                            <div style={{width: 200, marginLeft: 20}}>
                                                <Multiply
                                                    value={trede.factor || 0}
                                                    onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.age.${age}.${index}.factor`, parseFloat)}
                                                    plaintext={!edit}
                                                    readOnly={!edit}
                                                />
                                            </div>

                                            <div style={{width: 125, marginLeft: 20}}>
                                                <Price
                                                    value={(trede.salary || 0) * (trede.factor || 0)}
                                                    plaintext={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}


                    <h6 style={{width: 100}}>AOW+</h6>

                    {salaryTableInstance.aow.map((trede, index) => {
                        return (
                            <div key={`trede${index}`} style={{display: 'flex'}}>

                                <p style={{width: 100}}>{`Trede ${index}`}</p>

                                <div style={{width: 125, marginLeft: 20}}>

                                    <Price
                                        value={trede.salary || 0}
                                        onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.aow.${index}.salary`, parseFloat)}
                                        plaintext={!edit}
                                        readOnly={!edit}
                                    />
                                </div>

                                <div style={{width: 200, marginLeft: 20}}>
                                    <Multiply
                                        value={trede.factor || 0}
                                        onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.aow.${index}.factor`, parseFloat)}
                                        plaintext={!edit}
                                        readOnly={!edit}
                                    />
                                </div>

                                <div style={{width: 125, marginLeft: 20}}>
                                    <Price
                                        value={(trede.salary || 0) * (trede.factor || 0)}
                                        plaintext={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    <div style={{display: 'flex', marginTop: 15}}>
                        <h6 style={{width: 100}}>ZZP loon</h6>

                        <div style={{width: 125, marginLeft: 20}}>
                            <Price
                                value={salaryTableInstance.zzpSalary || 0}
                                onChange={this.onChangeValue.bind(this, `dates.${startDateIndex}.zzpSalary`, parseFloat)}
                                plaintext={!edit}
                                readOnly={!edit}
                            />
                        </div>
                    </div>


                    {message &&
                        <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                    }
                </Card.Body>

                <Card.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {edit ?
                            <Button onClick={this.onClickSave.bind(this)}>Opslaan</Button> :
                            <Button onClick={() => this.setState({edit: true, message: ''})}>Wijzigen</Button>
                        }
                        <Button onClick={this.props.onClickExport.bind(this)} >
                            Exporteer
                        </Button>

                        <Button variant='danger' onClick={this.onClickRemove.bind(this)} >
                            Verwijder
                        </Button>
                    </div>
                </Card.Footer>

                <Popup ref={(ref) => this.popup = ref}/>
            </Card>
        )
    }
}

export default Radium(SalaryTable)
