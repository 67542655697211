import React from 'react'
import Radium from 'radium'
import Reflux from 'reflux-react-16'

import LoginStore from '../stores/LoginStore.js'
import LoginActions from '../actions/LoginActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import Spinner from 'react-spinkit'
import {Colors} from '../components/UI/index.js'

class ResetPassword extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {password: '', password2: '', message: '', error: '', loading: false}
        this.store = LoginStore
    }

    onSubmit(event) {
        event.preventDefault()
        const {password, password2} = this.state
        const {token} = this.props.match.params
        this.setState({message: '', error: '', loading: true})

        if (password.length < 10) {
            this.setState({error: 'Het wachtwoord moet minstens 10 tekens bevatten.', loading: false})
        } else if (password !== password2) {
            this.setState({error: 'De ingevulde wachtwoorden komen niet overeen.', loading: false})
        } else {
            LoginActions.resetPassword(token, password, (err) => {
                if (err) {
                    this.setState({message: '', error: err, loading: false})
                } else {
                    this.setState({message: 'Je wachtwoord is gewijzigd.', error: '', loading: false})
                }
            })
        }
    }

    render() {
        const {password, password2, message, error, loading} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', background: Colors.lightGrey}}>

                <img style={{width: '90%', maxWidth: 450, marginTop: 100, objectFit: 'contain'}} src="/images/logo.png"/>

                <Card style={{width: '90%', maxWidth: 450, marginTop: 20}}>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                        <Card.Header>
                            <Card.Title>Wachtwoord wijzigen</Card.Title>
                        </Card.Header>

                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder='Wachtwoord (minstens 10 tekens)'
                                    value={password}
                                    onChange={(event) => this.setState({password: event.target.value})}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder='Herhaal wachtwoord'
                                    value={password2}
                                    onChange={(event) => this.setState({password2: event.target.value})}
                                />
                            </Form.Group>

                            {message &&
                                <Alert variant='success'>{message}</Alert>
                            }
                            {error &&
                                <Alert variant='danger'>{error}</Alert>
                            }
                        </Card.Body>

                        <Card.Footer>
                            <Button type="submit" disabled={loading}>
                                {loading ?
                                    <Spinner fadeIn='none'/> :
                                    'Bevestigen'
                                }
                            </Button>
                            <Button variant='link' href='/koerier/login'>Inloggen</Button>
                        </Card.Footer>
                    </Form>
                </Card>
            </div>
        )
    }
}

export default Radium(ResetPassword)
