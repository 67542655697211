import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import FileSaver from 'file-saver'
import encoding from 'encoding'
import _ from 'underscore'

import CustomerStore from '../stores/CustomerStore.js'
import UserStore from '../stores/UserStore.js'
import ResultStore from '../stores/ResultStore.js'
import ResultActions from '../actions/ResultActions.js'

import {Alert, Button, Card, Form, Row, Col, Spinner} from 'react-bootstrap'
import Select from 'react-select'

import {DatePicker} from '../components/forms/index.js'
import {ChartCard} from '../components/UI/index.js'

class Dashboard extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }

        this.stores = [CustomerStore, UserStore, ResultStore]
    }

    componentDidMount() {
        this.onClickRefreshData()
    }


    onClickGetCustomerRapportCSV() {
        const {customers, customerId, startDate, endDate} = this.state

        const customerOptions = {
            BENU: {_id: 'BENU', name: 'BENU'},
            Boots: {_id: 'Boots', name: 'Boots'},
            Overige: {_id: 'Overige', name: 'Overige'},
            Totaal: {_id: 'Totaal', name: 'Totaal'},
            Pakketbasis: {_id: 'Pakketbasis', name: 'Pakketbasis'},
            Uurbasis: {_id: 'Uurbasis', name: 'Uurbasis'},
            ...customers
        }

        this.setState({rapportLoading: true, error: ''})

        ResultActions.getCustomerRapportCSV((err, csv) => {
            if (err) {
                this.setState({rapportLoading: false, error: err})
            } else {
                this.setState({rapportLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Rapportage ${customerOptions[customerId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerResultCSV() {
        const {customers, customerId, startDate, endDate} = this.state

        this.setState({resultLoading: true, error: ''})

        ResultActions.getCustomerResultCSV((err, csv) => {
            if (err) {
                this.setState({resultLoading: false, error: err})
            } else {
                this.setState({resultLoading: false})
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Resultaat ${customers[customerId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }


    onClickGetCustomerSpecificationCSV() {
        const {customers, customerId, startDate, endDate} = this.state

        this.setState({specificationLoading: true, error: ''})

        ResultActions.getCustomerSpecificationCSV((err, csv) => {
            if (err) {
                this.setState({specificationLoading: false, error: err})
            } else {
                this.setState({specificationLoading: false})
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Specificatie ${customers[customerId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerSpecificationPDF() {
        const {customers, customerId, startDate, endDate} = this.state

        const customerOptions = {
            BENU: {_id: 'BENU', name: 'BENU'},
            Boots: {_id: 'Boots', name: 'Boots'},
            Overige: {_id: 'Overige', name: 'Overige'},
            Totaal: {_id: 'Totaal', name: 'Totaal'},
            ...customers
        }

        this.setState({specificationPDFLoading: true, error: ''})

        ResultActions.getCustomerSpecificationPDF((err, pdf) => {
            if (err) {
                this.setState({specificationPDFLoading: false, error: err})
            } else {
                this.setState({specificationPDFLoading: false})

                const raw = window.atob(pdf)
                const rawLength = raw.length
                const array = new Uint8Array(new ArrayBuffer(rawLength))

                for (let i = 0; i < rawLength; i++) {
                    array[i] = raw.charCodeAt(i)
                }

                const blob = new Blob([array], {type: 'application/pdf'})
                FileSaver.saveAs(blob, `Specificatie ${customerOptions[customerId]?.name} ${startDate}-${endDate}.pdf`)
            }
        })
    }

    onClickGetCoordinatorRapportCSV() {
        const {users, coordinatorId, startDate, endDate} = this.state

        this.setState({coordinatorRapportLoading: true, error: ''})

        ResultActions.getCoordinatorRapportCSV((err, csv) => {
            if (err) {
                this.setState({coordinatorRapportLoading: false, error: err})
            } else {
                this.setState({coordinatorRapportLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Coördinator ${users[coordinatorId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetRegionRapportCSV() {
        const {regionId, startDate, endDate} = this.state

        this.setState({regionRapportLoading: true, error: ''})

        ResultActions.getRegionRapportCSV((err, csv) => {
            if (err) {
                this.setState({regionRapportLoading: false, error: err})
            } else {
                this.setState({regionRapportLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Regio ${regionId} ${startDate}-${endDate}.csv`)
            }
        })
    }


    onClickGetUserResultCSV() {
        const {users, userId, startDate, endDate} = this.state

        this.setState({userResultLoading: true, error: ''})

        ResultActions.getUserResultCSV((err, csv) => {
            if (err) {
                this.setState({userResultLoading: false, error: err})
            } else {
                this.setState({userResultLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Rapportage ${users[userId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetUserSalaryCSV() {
        const {users, userId, startDate, endDate} = this.state

        const userOptions = {
            all: {_id: 'all', name: 'Alle medewerkers'},
            zzp: {_id: 'zzp', name: 'Alle medewerkers zzp'},
            contract: {_id: 'contract', name: 'Alle medewerkers in loondienst'},
            ...users
        }

        this.setState({userSalaryLoading: true, error: ''})

        ResultActions.getUserSalaryCSV((err, csv) => {
            if (err) {
                this.setState({userSalaryLoading: false, error: err})
            } else {
                this.setState({userSalaryLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Salaris ${userOptions[userId]?.name} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickRefreshData() {
        const {user} = this.props

        this.setState({revenueDataLoading: true, error: ''})

        if (user?.type === 'Admin') {
            ResultActions.getStatistics((err, statistics) => {
                if (err) {
                    this.setState({revenueDataLoading: false, error: err})
                } else {
                    this.setState({revenueDataLoading: false, statistics})
                }
            })
        }
    }


    onClickGetRevenueCSV() {
        const {startDate, endDate} = this.state

        this.setState({revenueExportLoading: true, error: ''})

        ResultActions.getRevenueCSV((err, csv) => {
            if (err) {
                this.setState({revenueExportLoading: false, error: err})
            } else {
                this.setState({revenueExportLoading: false})

                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Omzet ${startDate}-${endDate}.csv`)
            }
        })
    }

    render() {
        const {customers, customerId, regionId, coordinatorId, startDate, endDate, users, userId, statistics, error} = this.state
        const {user} = this.props

        let customerOptions = {
            BENU: {_id: 'BENU', name: 'BENU'},
            Boots: {_id: 'Boots', name: 'Boots'},
            Overige: {_id: 'Overige', name: 'Overige'},
            Totaal: {_id: 'Totaal', name: 'Totaal'},
            Pakketbasis: {_id: 'Pakketbasis', name: 'Pakketbasis'},
            Uurbasis: {_id: 'Uurbasis', name: 'Uurbasis'},
            ...customers
        }

        if (user?.type !== 'Admin') {
            customerOptions = {
                ...customers
            }
        }

        const userOptions = {
            all: {_id: 'all', name: 'Alle medewerkers'},
            zzp: {_id: 'zzp', name: 'Alle medewerkers zzp'},
            contract: {_id: 'contract', name: 'Alle medewerkers in loondienst'},
            ...users
        }

        const regionOptions = {
            Landelijk: {_id: 'Landelijk', name: 'Landelijk'},
            Almere: {_id: 'Almere', name: 'Almere'},
            Amsterdam: {_id: 'Amsterdam', name: 'Amsterdam'},
            Arnhem: {_id: 'Arnhem', name: 'Arnhem'},
            'Den Haag': {_id: 'Den Haag', name: 'Den Haag'},
            'Den Haag Auto': {_id: 'Den Haag Auto', name: 'Den Haag Auto'},
            'Den Haag-Moerwijk': {_id: 'Den Haag-Moerwijk', name: 'Den Haag-Moerwijk'},
            'Den Haag-Rijswijk': {_id: 'Den Haag-Rijswijk', name: 'Den Haag-Rijswijk'},
            Ede: {_id: 'Ede', name: 'Ede'},
            Leiden: {_id: 'Leiden', name: 'Leiden'},
            Limburg: {_id: 'Limburg', name: 'Limburg'},
            Meppel: {_id: 'Meppel', name: 'Meppel'},
            'Midden-Oost Brabant': {_id: 'Midden-Oost Brabant', name: 'Midden-Oost Brabant'},
            'Midden-West Brabant': {_id: 'Midden-West Brabant', name: 'Midden-West Brabant'},
            'Noord-Holland': {_id: 'Noord-Holland', name: 'Noord-Holland'},
            'Oost Nederland': {_id: 'Oost Nederland', name: 'Oost Nederland'},
            Rotterdam: {_id: 'Rotterdam', name: 'Rotterdam'},
            Utrecht: {_id: 'Utrecht', name: 'Utrecht'},
            Zeeland: {_id: 'Zeeland', name: 'Zeeland'}
        }

        const coordinatorOptions = {}
        _.values(users).filter((user) => user.type === 'Admin' || user.type === 'Coördinator').map((coordinator) => {
            coordinatorOptions[coordinator._id] = {_id: coordinator._id, name: coordinator.name}
        })


        if (!user) {
            return <div/>
        }

        return (
            <div style={{height: '100%', width: '100%'}}>
                {error &&
                    <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                }

                <div style={{display: 'flex', height: '100%', padding: 50}}>
                    <div>
                        <Card style={{width: 650, margin: 20}}>
                            <Card.Header>
                                <Card.Title>Klanten</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <Form.Group className="mb-3">
                                    <Select
                                        placeholder='Selecteer klant...'
                                        options={_.values(customerOptions).map((customer) => {
                                            return {value: customer._id, label: customer.name}
                                        })}
                                        value={customerId ? {value: customerId, label: customerOptions[customerId]?.name} : null}
                                        onChange={(option) => ResultActions.setCustomer(option.value)}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <DatePicker
                                            value={startDate}
                                            onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    disabled={this.state.rapportLoading || !customerId}
                                    style={{marginRight: 10, marginBottom: 16, minWidth: 150}}
                                    onClick={this.onClickGetCustomerRapportCSV.bind(this)}
                                >
                                    {this.state.rapportLoading ?
                                        <Spinner animation="border" role="status" size='sm'/> :
                                        'Rapportage .csv'
                                    }
                                </Button>

                                <Button
                                    disabled={this.state.resultLoading || !customerId || customerId === 'BENU' || customerId === 'Boots' || customerId === 'Overige' || customerId === 'Totaal' || customerId === 'Pakketbasis' || customerId === 'Uurbasis'}
                                    style={{marginRight: 10, marginBottom: 16, minWidth: 132}}
                                    onClick={this.onClickGetCustomerResultCSV.bind(this)}
                                >
                                    {this.state.resultLoading ?
                                        <Spinner animation="border" role="status" size='sm'/> :
                                        'Resultaat .csv'
                                    }
                                </Button>

                                { user?.type === 'Admin' &&
                                    <Button
                                        disabled={this.state.specificationLoading || !customerId || customerId === 'BENU' || customerId === 'Boots' || customerId === 'Overige' || customerId === 'Totaal' || customerId === 'Pakketbasis' || customerId === 'Uurbasis'}
                                        style={{marginRight: 10, marginBottom: 16, minWidth: 150}}
                                        onClick={this.onClickGetCustomerSpecificationCSV.bind(this)}
                                    >
                                        {this.state.specificationLoading ?
                                            <Spinner animation="border" role="status" size='sm'/> :
                                            'Specificatie .csv'
                                        }
                                    </Button>
                                }

                                { user?.type === 'Admin' &&
                                    <Button
                                        disabled={this.state.specificationPDFLoading || !customerId || customerId === 'Pakketbasis' || customerId === 'Uurbasis'}
                                        style={{marginBottom: 16, minWidth: 150}}
                                        onClick={this.onClickGetCustomerSpecificationPDF.bind(this)}
                                    >
                                        {this.state.specificationPDFLoading ?
                                            <Spinner animation="border" role="status" size='sm'/> :
                                            'Specificatie .pdf'
                                        }
                                    </Button>
                                }
                            </Card.Footer>
                        </Card>

                        <Card style={{width: 650, margin: 20}}>
                            <Card.Header>
                                <Card.Title>Teamleiders</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <Form.Group className="mb-3">
                                    <Select
                                        placeholder='Selecteer teamleider...'
                                        options={_.values(coordinatorOptions).map((coordinator) => {
                                            return {value: coordinator._id, label: coordinator.name}
                                        })}
                                        value={coordinatorId ? {value: coordinatorId, label: coordinatorOptions[coordinatorId]?.name} : null}
                                        onChange={(option) => ResultActions.setCoordinator(option.value)}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <DatePicker
                                            value={startDate}
                                            onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    disabled={this.state.coordinatorRapportLoading || !coordinatorId}
                                    style={{marginRight: 10, marginBottom: 16, minWidth: 150}}
                                    onClick={this.onClickGetCoordinatorRapportCSV.bind(this)}
                                >
                                    {this.state.coordinatorRapportLoading ?
                                        <Spinner animation="border" role="status" size='sm'/> :
                                        'Rapportage .csv'
                                    }
                                </Button>


                            </Card.Footer>
                        </Card>

                        { user?.type === 'Admin' &&
                            <Card style={{width: 650, margin: 20}}>
                                <Card.Header>
                                    <Card.Title>{'Regio\'s'}</Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <Form.Group className="mb-3">
                                        <Select
                                            placeholder='Selecteer regio...'
                                            options={_.values(regionOptions).map((region) => {
                                                return {value: region._id, label: region.name}
                                            })}
                                            value={regionId ? {value: regionId, label: regionOptions[regionId]?.name} : null}
                                            onChange={(option) => ResultActions.setRegion(option.value)}
                                        />
                                    </Form.Group>

                                    <Row>
                                        <Col>
                                            <DatePicker
                                                value={startDate}
                                                onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <DatePicker
                                                value={endDate}
                                                onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Button
                                        disabled={this.state.regionRapportLoading || !regionId}
                                        style={{marginRight: 10, marginBottom: 16, minWidth: 150}}
                                        onClick={this.onClickGetRegionRapportCSV.bind(this)}
                                    >
                                        {this.state.regionRapportLoading ?
                                            <Spinner animation="border" role="status" size='sm'/> :
                                            'Rapportage .csv'
                                        }
                                    </Button>


                                </Card.Footer>
                            </Card>
                        }

                        { user?.type === 'Admin' &&
                            <Card style={{width: 650, margin: 20}}>
                                <Card.Header>
                                    <Card.Title>Medewerkers</Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <Form.Group className="mb-3">
                                        <Select
                                            placeholder='Selecteer medewerker...'
                                            options={_.values(userOptions).map((user) => {
                                                return {value: user._id, label: user.name}
                                            })}
                                            value={userId ? {value: userId, label: userOptions[userId]?.name} : null}
                                            onChange={(option) => ResultActions.setUser(option.value)}
                                        />
                                    </Form.Group>

                                    <Row>
                                        <Col>
                                            <DatePicker
                                                value={startDate}
                                                onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <DatePicker
                                                value={endDate}
                                                onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>

                                <Card.Footer>
                                    <Button
                                        disabled={this.state.userResultLoading || !userId || userId === 'all' || userId === 'zzp' || userId === 'contract'}
                                        style={{marginRight: 10, marginBottom: 16, minWidth: 132}}
                                        onClick={this.onClickGetUserResultCSV.bind(this)}
                                    >
                                        {this.state.userResultLoading ?
                                            <Spinner animation="border" role="status" size='sm'/> :
                                            'Resultaat .csv'
                                        }
                                    </Button>

                                    <Button
                                        disabled={this.state.userSalaryLoading || !userId}
                                        style={{marginRight: 10, marginBottom: 16, minWidth: 112}}
                                        onClick={this.onClickGetUserSalaryCSV.bind(this)}
                                    >
                                        {this.state.userSalaryLoading ?
                                            <Spinner animation="border" role="status" size='sm'/> :
                                            'Salaris .csv'
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>
                        }
                    </div>

                    { user?.type === 'Admin' &&
                        <div style={{margin: 20}}>
                            <ChartCard
                                title='Omzet & kosten'
                                data={statistics}
                                footer={
                                    <div style={{display: 'flex'}}>
                                        <DatePicker
                                            style={{width: 190, marginRight: 10}}
                                            value={startDate}
                                            onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                        />
                                        <DatePicker
                                            style={{width: 190, marginRight: 10}}
                                            value={endDate}
                                            onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                        />
                                        <Button
                                            disabled={this.state.revenueDataLoading}
                                            style={{minWidth: 42}}
                                            onClick={this.onClickRefreshData.bind(this)}
                                        >
                                            {this.state.revenueDataLoading ?
                                                <Spinner animation="border" role="status" size='sm'/> :
                                                <i className='mdi mdi-refresh'/>
                                            }
                                        </Button>

                                        <Button
                                            disabled={this.state.revenueExportLoading}
                                            style={{marginLeft: 10, minWidth: 165}}
                                            onClick={this.onClickGetRevenueCSV.bind(this)}
                                        >
                                            {this.state.revenueExportLoading ?
                                                <Spinner animation="border" role="status" size='sm'/> :
                                                ' Omzet export .csv'
                                            }

                                        </Button>
                                    </div>
                                }
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}


export default Radium(Dashboard)
