import Reflux from 'reflux-react-16'
import _ from 'underscore'
import moment from 'moment'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import StopActions from '../actions/StopActions.js'

export default class StopStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            date: moment().format('YYYY-MM-DD'),
            stops: [],
            filteredStops: [],
            selectedStops: [],
            stopsLoading: false,
            stopsSortKey: 'date',
            stopsSortReverse: false,
            stopQueries: []
        }

        this.listenables = StopActions

        StopActions.get(true)

        socket.on('updateStops', () => {
            StopActions.get()
        })
    }

    onSetDate(date) {
        this.setState({date})
        StopActions.get(true)
    }

    onChangeQueries(stopQueries) {
        const {stops} = this.state

        const filteredStops = filter.stops(stops, stopQueries)

        this.setState({filteredStops, stopQueries})
    }

    onChangeSortKey(newSortKey) {
        let {filteredStops, stopsSortKey, stopsSortReverse} = this.state

        if (stopsSortKey === newSortKey) {
            stopsSortReverse = !stopsSortReverse
        } else {
            stopsSortReverse = false
        }

        stopsSortKey = newSortKey

        filteredStops = _.sortBy(filteredStops, stopsSortKey)

        if (stopsSortReverse) {
            filteredStops.reverse()
        }

        this.setState({filteredStops, stopsSortKey, stopsSortReverse})
    }

    onSetSelected(selectedStops) {
        this.setState({selectedStops})
    }

    onGet(loading) {
        const {date} = this.state
        this.setState({stopsLoading: !!loading})

        socket.emit('stops.get', sessionStorage.token, date, (err, stops) => {
            if (!err) {
                const {stopQueries, stopsSortKey, stopsSortReverse} = this.state

                let filteredStops = filter.stops(stops, stopQueries)

                filteredStops = _.sortBy(filteredStops, stopsSortKey)
                if (stopsSortReverse) {
                    filteredStops.reverse()
                }

                this.setState({stops, filteredStops, stopsLoading: false})
            }
        })
    }

    onFindByBarcode(...args) {
        socket.emit('stops.findByBarcode', ...args)
    }

    importStops(...args) {
        socket.emit('stops.importStops', sessionStorage.token, ...args)
    }
}
