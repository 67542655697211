import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import _ from 'underscore'

import CustomerStore from '../stores/CustomerStore.js'
import CustomerActions from '../actions/CustomerActions.js'
import SettingsStore from '../stores/SettingsStore.js'
import UserStore from '../stores/UserStore.js'

import {Button, Form, Table} from 'react-bootstrap'
import {Colors, Popup, SearchBar} from '../components/UI/index.js'
import CustomerModal from '../components/customers/CustomerModal.js'
import FileSaver from 'file-saver'
import moment from 'moment'

class Customers extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [CustomerStore, SettingsStore, UserStore]
    }

    columns() {
        const {users, priceTables, messageSets} = this.state

        return [
            {title: 'Smartroute', visible: true, key: 'synced', renderText: (customer) => customer.synced ? 'Ja' : 'Nee', render: (customer) => {
                return (
                    <i style={{fontSize: 12, color: customer.synced ? Colors.success: Colors.danger}} className="mdi mdi-circle"/>
                )
            }},
            {title: 'Aangemaakt op', hidden: true, key: '_id', render: (customer) => {
                const dateFromObjectId = new Date(parseInt(customer._id.substring(0, 8), 16) * 1000)
                return moment(dateFromObjectId).format('DD-MM-YYYY')
            }},
            {title: 'Actief', visible: true, key: 'active', render: (customer) => customer.active ? 'Ja' : 'Nee'},
            {title: 'Groep', visible: true, key: 'groupId', render: (customer) => customer.groupId},
            {title: 'Regio', key: 'region', render: (customer) => customer.region},
            {title: 'Teamleider', key: 'teamLeader', render: (customer) => users[customer.teamLeader]?.name || ''},
            {title: 'Naam', visible: true, key: 'name', render: (customer) => customer.name},
            {title: 'Straat', key: 'address.street', render: (customer) => customer.address.street},
            {title: 'Nr', key: 'address.nr', render: (customer) => customer.address.nr},
            {title: 'Toevoeging', key: 'address.addition', render: (customer) => customer.address.addition},
            {title: 'Postcode', key: 'address.postalCode', render: (customer) => customer.address.postalCode},
            {title: 'Plaats', key: 'address.city', render: (customer) => customer.address.city},
            {title: 'Email', key: 'email', render: (customer) => customer.email},
            {title: 'Tarieftabel', key: 'priceTable', render: (customer) => priceTables[customer.priceTable]?.name || ''},
            {title: 'Berichtenset', key: 'messageSet', render: (customer) => messageSets[customer.messageSet]?.name || ''}
        ]
    }

    onClickNew() {
        this.customerModal.open()
        CustomerActions.setSelected([])
    }

    onClickEdit(customer) {
        this.customerModal.open(customer)
        CustomerActions.setSelected([])
    }

    onClickSelectAll() {
        const {customers, selectedCustomers} = this.state

        if (customers.length === selectedCustomers.length) {
            CustomerActions.setSelected([])
        } else {
            CustomerActions.setSelected(_.pluck(customers, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedCustomers = JSON.parse(JSON.stringify(this.state.selectedCustomers))
        const index = selectedCustomers.indexOf(id)

        if (index === -1) {
            selectedCustomers.push(id)
        } else {
            selectedCustomers.splice(index, 1)
        }

        CustomerActions.setSelected(selectedCustomers)
    }

    onClickExport() {
        const {customers, selectedCustomers} = this.state
        const exportCustomers = []

        selectedCustomers.map((id) => {
            const customer = _.find(customers, (customer) => customer._id === id)

            if (customer) {
                exportCustomers.push(customer)
            }
        })

        const name = 'Klanten.csv'
        const content = [this.columns().map((column) => column.title).join(';')]

        exportCustomers.map((customer) => {
            const line = []

            this.columns().map((column) => {
                if (column.renderText) {
                    line.push(column.renderText(customer))
                } else {
                    line.push(column.render(customer))
                }
            })

            content.push(line.join(';'))
        })

        const csv = content.join('\n')

        // utf-8 BOM to display € correctly - https://stackoverflow.com/questions/56154046/downloading-blob-with-type-text-csv-strips-unicode-bom
        const buffer = new ArrayBuffer(3)
        const dataView = new DataView(buffer)
        dataView.setUint8(0, 0xef)
        dataView.setUint8(1, 0xbb)
        dataView.setUint8(2, 0xbf)
        const read = new Uint8Array(buffer)

        const blob = new Blob([read, csv], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, name)


        CustomerActions.setSelected([])
    }

    render() {
        const {filteredCustomers, selectedCustomers, customerQueries, customersSortKey, customersSortReverse, users, priceTables, messageSets} = this.state
        const {user} = this.props

        if (!user || user.type !== 'Admin') {
            return <div/>
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <SearchBar
                        columns={this.columns()}
                        queries={customerQueries || []}
                        onChange={CustomerActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        <Button style={{marginLeft: 10}} onClick={this.onClickNew.bind(this)}>Nieuwe Klant</Button>


                        {selectedCustomers.length > 0 &&
                        <>
                            {user?.type === 'Admin' &&
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={this.onClickExport.bind(this)}
                                >
                                    CSV Export
                                </Button>
                            }
                        </>
                        }
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={_.keys(filteredCustomers).length && selectedCustomers.length === _.keys(filteredCustomers).length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {this.columns().map((column) => {
                                    if (column.hidden) return
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => CustomerActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === customersSortKey && customersSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === customersSortKey && !customersSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {_.values(filteredCustomers).map((customer) => {
                                return (
                                    <tr key={customer._id}>

                                        <td>
                                            <Form.Check
                                                checked={selectedCustomers.indexOf(customer._id) > -1}
                                                onChange={this.onClickSelect.bind(this, customer._id)}
                                            />
                                        </td>
                                        {this.columns().map((column) => {
                                            if (column.hidden) return
                                            return <td key={column.key}>{column.render(customer)}</td>
                                        })}
                                        <td>
                                            <Button size='sm' onClick={this.onClickEdit.bind(this, customer)}>
                                                <i className='mdi mdi-pencil'/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                <CustomerModal
                    users={users}
                    priceTables={priceTables}
                    messageSets={messageSets}
                    ref={(ref) => this.customerModal = ref}
                />
                <Popup ref={(ref) => this.popup = ref}/>

            </div>
        )
    }
}

export default Radium(Customers)
