import Reflux from 'reflux-react-16'
import jwt from 'jsonwebtoken'
import socket from '../../socketio/socketio.js'

import LoginActions from '../actions/LoginActions.js'

export default class LoginStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {user: jwt.decode(sessionStorage.token) || {settings: {}}}
        this.listenables = LoginActions

        socket.on('logout', () => {
            LoginActions.logout()
        })
    }

    onLogin(email, password, callback) {
        socket.emit('login.login', email, password, (err, token) => {
            if (!err) {
                sessionStorage.token = token
                const user = jwt.decode(token)
                this.setState({user})
                callback(null, user)
            } else {
                callback(err)
            }
        })
    }

    onLogout() {
        sessionStorage.removeItem('token')
        window.location = '/login'
    }

    onLogoutAll() {
        socket.emit('login.logoutAll')
    }

    onLoggedIn(callback) {
        callback(sessionStorage.token, this.state.user)
    }


    onSendResetPasswordMail(email, callback) {
        socket.emit('login.sendResetPasswordMail', email, callback)
    }

    onResetPassword(token, password, callback) {
        socket.emit('login.resetPassword', token, password, callback)
    }
}
