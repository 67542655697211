import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import {Route} from 'react-router-dom'

import {Navbar, Nav, Dropdown} from 'react-bootstrap'
import {Colors, Popup} from '../components/UI/index.js'

import LoginStore from '../stores/LoginStore.js'
import LoginActions from '../actions/LoginActions.js'

import Dashboard from './Dashboard.js'
import Stops from './Stops.js'
import Shifts from './Shifts.js'
import Customers from './Customers.js'
import Users from './Users.js'
import Settings from './Settings.js'

class Layout extends Reflux.Component {
    constructor(props) {
        super(props)
        this.store = LoginStore
    }

    componentWillMount() {
        Reflux.Component.prototype.componentWillMount.call(this)
        const {history} = this.props

        LoginActions.loggedIn((loggedIn) => {
            if (!loggedIn) {
                history.push('/login')
            }
        })
    }

    onNavigate(link) {
        const {history} = this.props
        history.push(link)
    }

    onClickLogout() {
        LoginActions.logout()
    }

    onClickLogoutAll() {
        this.popup.open('Alle accounts uitloggen', 'Hiermee worden alle actieve gebruikers uitgelogd. Weet je dit zeker?', () => {
            LoginActions.logoutAll()
        })
    }

    render() {
        const {user} = this.state
        const {pathname} = this.props.location

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', minWidth: 320}}>
                <Navbar collapseOnSelect bg="light" variant="light" expand='md' fixed="top">
                    <Navbar.Brand style={{marginLeft: 15}}>
                        <img src='/images/logo_small.png' height={38}/>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link
                                active={pathname === '/dashboard'}
                                onClick={this.onNavigate.bind(this, '/dashboard')}
                            >
                                Dashboard
                            </Nav.Link>

                            <Nav.Link
                                active={pathname === '/stops'}
                                onClick={this.onNavigate.bind(this, '/stops')}
                            >
                                Stops
                            </Nav.Link>

                            <Nav.Link
                                active={pathname === '/diensten'}
                                onClick={this.onNavigate.bind(this, '/diensten')}
                            >
                                Diensten
                            </Nav.Link>

                            {user.type === 'Admin' &&
                                <Nav.Link
                                    active={pathname === '/klanten'}
                                    onClick={this.onNavigate.bind(this, '/klanten')}
                                >
                                    Klanten
                                </Nav.Link>
                            }

                            <Nav.Link
                                active={pathname === '/gebruikers'}
                                onClick={this.onNavigate.bind(this, '/gebruikers')}
                            >
                                Gebruikers
                            </Nav.Link>
                        </Nav>

                        <Nav style={{marginLeft: 'auto'}}>
                            <Dropdown as={Nav.Item}>
                                <Dropdown.Toggle as={Nav.Link} style={{minWidth: 230}}>{user.email}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.onNavigate.bind(this, '/instellingen')}>
                                        <i className='mdi mdi-cog'/> Instellingen
                                    </Dropdown.Item>

                                    <Dropdown.Item onClick={this.onClickLogout.bind(this)}>
                                        <i className='mdi mdi-logout'/> Uitloggen
                                    </Dropdown.Item>

                                    <Dropdown.Item onClick={this.onClickLogoutAll.bind(this)}>
                                        <i className='mdi mdi-logout'/> Alle accounts uitloggen
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <div style={{height: '100%', overflow: 'auto', paddingTop: 64, background: Colors.lightGrey}}>
                    <Route path="/dashboard" render={(props) => <Dashboard user={user}/>}/>
                    <Route path="/stops" render={(props) => <Stops user={user}/>}/>
                    <Route path="/diensten" render={(props) => <Shifts user={user}/>}/>
                    <Route path="/klanten" render={(props) => <Customers user={user}/>}/>
                    <Route path="/gebruikers" render={(props) => <Users user={user}/>}/>
                    <Route path="/instellingen" render={(props) => <Settings user={user}/>}/>
                </div>

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Radium(Layout)
