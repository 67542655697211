import React from 'react'
import Leaflet from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import togeojson from 'togeojson'
import fileLayer from 'leaflet-filelayer'
import _ from 'lodash'

import {Button, Form} from 'react-bootstrap'

import {
    Colors
} from './index.js'

fileLayer(null, Leaflet, togeojson)

class MapZones extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hiddenZones: {

            }
        }
    }

    componentDidMount() {
        this.initMap()
    }

    componentDidUpdate(prevProps) {
        if (!this.map) {
            this.initMap()
        } else if (prevProps.zones !== this.props.zones) {
            this.updateMap()
        }
    }

    initMap() {
        const zones = [...this.props.zones]

        this.map = Leaflet.map('map', {
            preferCanvas: true,
            boxZoom: false
        }).setView({lat: 52.2076823, lng: 5.1584931}, 13)

        Leaflet.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            maxZoom: 18,
            tileSize: 512,
            zoomOffset: -1,
            accessToken: 'pk.eyJ1IjoidmVsb21lZGkiLCJhIjoiY2xlZTFoY2V6MGNvbTNvbXg5ZHRjdnBqNiJ9.W-Y8SVYUvQQhHzeo_94n2w',
            attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
        }).addTo(this.map)


        this.map.pm.addControls({
            position: 'topleft',
            drawPolygon: true,
            cutPolygon: true,
            drawMarker: false,
            drawPolyline: false,
            drawCircleMarker: false,
            drawRectangle: false,
            drawCircle: true,
            drawText: false,
            dragMode: true,
            rotateMode: true,
            removalMode: false
        })


        this.map.pm.setLang('nl')


        this.map.pm.setGlobalOptions({
            hintlineStyle: {color: '#242424', weight: 1, dashArray: [5, 5]},
            templineStyle: {color: '#242424', weight: 1},
            allowSelfIntersection: false
        })


        this.map.pm.Toolbar.changeActionsOfControl('Polygon', ['cancel', 'removeLastVertex'])
        this.map.pm.Toolbar.changeActionsOfControl('Edit', ['cancel', 'undo'])
        this.map.pm.Toolbar.changeActionsOfControl('Cut', ['cancel', 'removeLastVertex'])
        this.map.pm.Toolbar.changeActionsOfControl('Drag', ['cancel'])
        this.map.pm.Toolbar.changeActionsOfControl('Rotate', ['cancel'])


        this.drawnItems = new Leaflet.FeatureGroup()

        this.map.addLayer(this.drawnItems)


        this.map.on('pm:create', ({shape, layer}) => {
            const id = '_' + Math.random().toString(36).substring(2, 9)

            const zone = {
                id,
                name: `Gebied ${zones.length + 1}`,
                number: '1',
                geoJSON: shape === 'Circle' ? Leaflet.PM.Utils.circleToPolygon(layer, 60).toGeoJSON() : layer.toGeoJSON()
            }


            layer.setStyle({
                color: Colors.zoneColors[zone.number],
                weight: 1,
                fillOpacity: .2,
                opacity: 1
            })


            layer.options.id = id


            this.drawnItems.addLayer(layer)
            this.pmEvents(layer)


            this.props.onChange({target: {value: [...this.props.zones, zone]}})
        })


        this.updateMap(true)
    }

    updateMap(init) {
        const {hiddenZones} = this.state
        const {zones} = this.props

        this.drawnItems.clearLayers()

        zones.map((zone, index) => {
            if (hiddenZones[index]) {
                return
            }

            Leaflet.geoJson(zone.geoJSON, {
                style: (feature) => {
                    return {
                        color: Colors.zoneColors[zone.number],
                        weight: 1,
                        fillOpacity: .2,
                        opacity: 1
                    }
                },
                onEachFeature: (feature, layer) => {
                    layer.options.id = zone.id
                    this.drawnItems.addLayer(layer)

                    this.pmEvents(layer)
                }
            })
        })


        if (init) {
            setTimeout(() => {
                this.map.invalidateSize()
                const bounds = this.drawnItems.getBounds()
                this.map.fitBounds(bounds)
            }, 200)
        }
    }


    onChangeValue(path, event) {
        const zones = [...this.props.zones]

        _.set(zones, path, event.target.value)

        this.props.onChange({target: {value: zones}})
    }

    onClickRemoveZone(index, event) {
        const zones = [...this.props.zones]

        zones.splice(index, 1)

        this.props.onChange({target: {value: zones}})
    }

    onClickHideZone(index, event) {
        const hiddenZones = {...this.state.hiddenZones}

        hiddenZones[index] = !hiddenZones[index]

        this.setState({hiddenZones}, () => {
            this.updateMap()
        })
    }

    pmEvents(layer) {
        layer.on('pm:edit', (e) => {
            if (!e.layer.cutted) {
                const zones = [...this.props.zones]

                const zone = _.find(zones, {id: e.layer.options.id})

                if (zone) {
                    zone.geoJSON = e.layer.toGeoJSON()
                }

                this.props.onChange({target: {value: zones}})
            }
        })

        layer.on('pm:cut', (e) => {
            const zones = [...this.props.zones]

            const zone = _.find(zones, {id: e.layer.options.id})

            e.originalLayer.cutted = true // Important; prevents pm:edit event

            if (zone) {
                zone.geoJSON = e.layer.toGeoJSON()
            }

            this.props.onChange({target: {value: zones}})
        })
    }


    render() {
        const {hiddenZones} = this.state
        const {zones} = this.props

        return (
            <div>
                <div style={{width: '100%'}}>

                    <div id="map" style={{width: '100%', height: 500, marginBottom: 15}}></div>

                    {zones.map((zone, index) => {
                        return (
                            <div
                                key={zone.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <i style={{fontSize: 26, color: Colors.zoneColors[zone.number || 1], marginBottom: 15}} className='mdi mdi-circle'/>

                                <Form.Group className="mb-3" style={{marginLeft: 15, width: 200}}>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={zone.number}
                                        onChange={this.onChangeValue.bind(this, `${index}.number`)}
                                    >
                                        <option value="1">Zone 1</option>
                                        <option value="2">Zone 2</option>
                                        <option value="3">Zone 3</option>
                                        <option value="4">Zone 4</option>

                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" style={{marginLeft: 15, flex: 1}}>
                                    <Form.Control
                                        value={zone.name}
                                        onChange={this.onChangeValue.bind(this, `${index}.name`)}
                                    />
                                </Form.Group>

                                <Button style={{marginBottom: 15, marginLeft: 15}} onClick={this.onClickHideZone.bind(this, index)}><i className={hiddenZones[index] ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}/></Button>
                                <Button style={{marginBottom: 15, marginLeft: 15}} onClick={this.onClickRemoveZone.bind(this, index)}><i className='mdi mdi-delete'/></Button>
                            </div>
                        )
                    })}
                </div>


            </div>
        )
    }
}

export default MapZones
