import React from 'react'
import Radium from 'radium'

import {Form} from 'react-bootstrap'
import {IconButton, Colors} from '../UI/index.js'


class DeliveryTable extends React.Component {
    onClickMinDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        route.deliveries[zoneName] = route.deliveries[zoneName] || {}
        route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
        if (route.deliveries[zoneName][categoryName].delivered > 0) {
            route.deliveries[zoneName][categoryName].delivered -= 1
        }

        this.props.onChange(route)
    }

    onClickAddDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        route.deliveries[zoneName] = route.deliveries[zoneName] || {}
        route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
        if (route.deliveries[zoneName][categoryName].delivered < 99999) {
            route.deliveries[zoneName][categoryName].delivered += 1
        }

        this.props.onChange(route)
    }

    onChangeDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        if (/^$|^[0-9]{0,5}$/.test(event.target.value)) {
            route.deliveries[zoneName] = route.deliveries[zoneName] || {}
            route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
            route.deliveries[zoneName][categoryName].delivered = parseInt(event.target.value) || 0

            this.props.onChange(route)
        }
    }


    onClickMinNotDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        route.deliveries[zoneName] = route.deliveries[zoneName] || {}
        route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
        if (route.deliveries[zoneName][categoryName].notDelivered > 0) {
            route.deliveries[zoneName][categoryName].notDelivered -= 1
        }

        this.props.onChange(route)
    }

    onClickAddNotDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        route.deliveries[zoneName] = route.deliveries[zoneName] || {}
        route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
        if (route.deliveries[zoneName][categoryName].notDelivered < route.deliveries[zoneName][categoryName].delivered) {
            route.deliveries[zoneName][categoryName].notDelivered += 1
        }

        this.props.onChange(route)
    }

    onChangeNotDelivered(zoneName, categoryName, event) {
        const route = {...this.props.route}

        if (/^$|^[0-9]{0,5}$/.test(event.target.value)) {
            route.deliveries[zoneName] = route.deliveries[zoneName] || {}
            route.deliveries[zoneName][categoryName] = route.deliveries[zoneName][categoryName] || {delivered: 0, notDelivered: 0}
            if ((parseInt(event.target.value) || 0) <= route.deliveries[zoneName][categoryName].delivered) {
                route.deliveries[zoneName][categoryName].notDelivered = parseInt(event.target.value) || 0
            }

            this.props.onChange(route)
        }
    }

    render() {
        const {route} = this.props

        const zones = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Geen zone']
        const categories = ['Aanbelpakket', 'Brievenbuspakket', 'XL-Pakket', 'Instelling/Bulk levering', 'Tijdsafspraak', 'Koelpakket', 'Ophaalopdracht']

        return (
            <div>
                {zones.map((zoneName) => {
                    return (
                        <div key={zoneName}>
                            <h6>{zoneName}</h6>

                            {categories.map((categoryName) => {
                                return (
                                    <div key={categoryName} style={{display: 'flex', alignItems: 'center', marginBottom: 3}}>
                                        <div style={{width: '100%', marginRight: 10}}>{categoryName}</div>

                                        <IconButton
                                            style={{color: Colors.primary, background: 'white'}}
                                            onClick={this.onClickMinDelivered.bind(this, zoneName, categoryName)}
                                        >
                                            <i className='mdi mdi-minus'/>
                                        </IconButton>

                                        <Form.Control
                                            style={{width: 60, marginRight: 5, marginLeft: 5}}
                                            value={route.deliveries[zoneName]?.[categoryName]?.delivered.toString() || ''}
                                            onChange={this.onChangeDelivered.bind(this, zoneName, categoryName)}
                                        />

                                        <IconButton
                                            style={{color: Colors.primary, background: 'white'}}
                                            onClick={this.onClickAddDelivered.bind(this, zoneName, categoryName)}
                                        >
                                            <i className='mdi mdi-plus'/>
                                        </IconButton>

                                        <IconButton
                                            style={{marginLeft: 30, color: Colors.primary, background: 'white'}}
                                            onClick={this.onClickMinNotDelivered.bind(this, zoneName, categoryName)}
                                        >
                                            <i className='mdi mdi-minus'/>
                                        </IconButton>

                                        <Form.Control
                                            style={{width: 60, marginRight: 5, marginLeft: 5}}
                                            value={route.deliveries[zoneName]?.[categoryName]?.notDelivered.toString() || ''}
                                            onChange={this.onChangeNotDelivered.bind(this, zoneName, categoryName)}
                                        />

                                        <IconButton
                                            style={{color: Colors.primary, background: 'white'}}
                                            onClick={this.onClickAddNotDelivered.bind(this, zoneName, categoryName)}
                                        >
                                            <i className='mdi mdi-plus'/>
                                        </IconButton>
                                    </div>
                                )
                            })}
                            <br/>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Radium(DeliveryTable)
