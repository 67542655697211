import React from 'react'
import Radium from 'radium'
import Reflux from 'reflux-react-16'

import LoginStore from '../stores/LoginStore.js'
import LoginActions from '../actions/LoginActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import Spinner from 'react-spinkit'
import {Colors} from '../components/UI/index.js'

class Login extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {email: '', password: '', loading: false, error: ''}
        this.store = LoginStore
    }

    componentWillMount() {
        Reflux.Component.prototype.componentWillMount.call(this)
        const {history} = this.props

        LoginActions.loggedIn((loggedIn, user) => {
            if (loggedIn) {
                if (user.type === 'Admin') {
                    history.push('/dashboard')
                } else {
                    history.push('/diensten')
                }
            }
        })
    }

    onSubmit(event) {
        event.preventDefault()
        this.setState({error: ''})

        const {email, password} = this.state
        const {history} = this.props

        LoginActions.login(email, password, (err, user) => {
            if (!err) {
                if (user.type === 'Admin') {
                    history.push('/dashboard')
                } else {
                    history.push('/diensten')
                }
            } else {
                this.setState({error: err})
            }
        })
    }

    render() {
        const {email, password, loading, error} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', background: Colors.lightGrey}}>

                <img style={{width: '90%', maxWidth: 450, marginTop: 100, objectFit: 'contain'}} src="/images/logo.png"/>

                <Card style={{width: '90%', maxWidth: 450, marginTop: 20}}>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                        <Card.Header>
                            <Card.Title>Inloggen</Card.Title>
                        </Card.Header>

                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    placeholder='Email'
                                    value={email}
                                    onChange={(event) => this.setState({email: event.target.value, error: ''})}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="password"
                                    placeholder="Wachtwoord"
                                    value={password}
                                    onChange={(event) => this.setState({password: event.target.value, error: ''})}
                                />
                            </Form.Group>

                            {error &&
                                <Alert variant='danger'>{error}</Alert>
                            }
                        </Card.Body>

                        <Card.Footer>
                            <Button type="submit" disabled={loading}>
                                {loading ? <Spinner fadeIn='none'/> :'Inloggen'}
                            </Button>
                            <Button variant='link' href='/wachtwoordvergeten'>Wachtwoord vergeten?</Button>
                        </Card.Footer>
                    </Form>
                </Card>
            </div>
        )
    }
}

export default Radium(Login)
