import React from 'react'
import Spinner from 'react-spinkit'
import {Alert, Button, Modal} from 'react-bootstrap'

class Popup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            loading: false,
            title: '',
            text: '',
            confirm: null,
            message: '',
            error: '',
            errors: []
        }
        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(title, text, confirm) {
        this.setState({isOpen: true, title, text, confirm})
    }

    close() {
        this.setState(this.initialState)
    }

    confirm() {
        this.setState({loading: true, message: '', error: '', errors: []})
        this.state.confirm()
    }

    setMessage(message) {
        this.setState({message, loading: false})
    }

    setError(error) {
        this.setState({error, loading: false})
    }
    setErrors(errors) {
        this.setState({errors, loading: false})
    }

    render() {
        const {isOpen, title, text, confirm, loading, message, error, errors} = this.state

        return (
            <Modal show={isOpen} onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{text}</p>

                    {message &&
                        <Alert variant='success'>{message}</Alert>
                    }

                    {error &&
                        <Alert variant='danger'>{error}</Alert>
                    }

                    {errors?.length > 0 && errors.map((error, index) => {
                        return <Alert key={`error_${index}`} variant='danger'>{error}</Alert>
                    })}
                </Modal.Body>

                <Modal.Footer>
                    {!confirm &&
                        <Button
                            variant="primary"
                            onClick={this.close.bind(this)}
                        >
                            Ok
                        </Button>
                    }
                    {confirm && !message && !error &&
                        <Button
                            variant="primary"
                            disabled={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            {loading ?
                                <Spinner fadeIn='none'/> :
                                'Bevestigen'
                            }
                        </Button>
                    }
                    {confirm && !message && !error &&
                        <Button
                            variant="secondary"
                            disabled={loading}
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

export default Popup
