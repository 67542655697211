import React from 'react'
import Radium from 'radium'
import moment from 'moment'

import StopActions from '../../actions/StopActions.js'

import {Alert, Modal, Row, Col} from 'react-bootstrap'
import {Divider, Popup} from '../UI/index.js'
import {DatePicker} from '../forms/index.js'

class StopModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            stop: null,
            modalIsOpen: false,
            edit: false,
            loading: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(stop) {
        console.log(stop)

        this.setState({
            modalIsOpen: true,
            stop
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onClickSaveStop() {
        const {stop, edit} = this.state

        if (edit) {
            StopActions.update(stop, (err, stop) => {
                if (!err) {
                    this.setState({stop})
                } else {
                    this.setState({error: err})
                }
            })
        }

        this.setState({edit: !edit})
    }

    onChangeDate(event) {
        const {stop} = this.state
        stop.date = event.target.value
        this.setState({stop})
    }


    render() {
        const {modalIsOpen, stop, edit, error} = this.state

        if (modalIsOpen && stop) {
            return (
                <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Stop info
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <p>Datum</p>
                                {!edit ?
                                    <h6>{moment(stop.date).format('DD-MM-YYYY')}</h6> :
                                    <DatePicker
                                        value={stop.date}
                                        onChange={this.onChangeDate.bind(this)}
                                    />
                                }
                            </Col>
                            <Col>
                                <p>Klant</p>
                                <h6>{stop.customerName}</h6>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>


                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>

                    </Modal.Footer>

                    <Popup ref={(ref) => this.popup = ref}/>
                </Modal>
            )
        } else {
            return <div/>
        }
    }
}

export default Radium(StopModal)
