import Reflux from 'reflux-react-16'
import _ from 'underscore'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import CustomerActions from '../actions/CustomerActions.js'

export default class CustomerStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            customers: {},
            filteredCustomers: {},
            selectedCustomers: [],
            customersLoading: false,
            customersSortKey: 'name',
            customersSortReverse: false,
            customerQueries: []
        }

        this.listenables = CustomerActions

        CustomerActions.get(true)

        socket.on('updateCustomers', () => {
            CustomerActions.get()
        })
    }

    onChangeQueries(customerQueries) {
        const {customers} = this.state

        const filteredCustomers = filter.customers(customers, customerQueries)

        this.setState({filteredCustomers, customerQueries})
    }

    onChangeSortKey(newSortKey) {
        let {filteredCustomers, customersSortKey, customersSortReverse} = this.state

        if (customersSortKey === newSortKey) {
            customersSortReverse = !customersSortReverse
        } else {
            customersSortReverse = false
        }

        customersSortKey = newSortKey

        filteredCustomers = _.sortBy(filteredCustomers, customersSortKey)

        if (customersSortReverse) {
            filteredCustomers.reverse()
        }

        this.setState({filteredCustomers, customersSortKey, customersSortReverse})
    }

    onSetSelected(selectedCustomers) {
        this.setState({selectedCustomers})
    }

    onGet(loading) {
        this.setState({customersLoading: !!loading})

        socket.emit('customers.get', sessionStorage.token, (err, customers) => {
            if (!err) {
                const {customerQueries, customersSortKey, customersSortReverse} = this.state

                let filteredCustomers = filter.customers(customers, customerQueries)

                filteredCustomers = _.sortBy(filteredCustomers, customersSortKey)
                if (customersSortReverse) {
                    filteredCustomers.reverse()
                }

                this.setState({customers, filteredCustomers, customersLoading: false})
            }
        })
    }

    onUpdate(customer, callback) {
        socket.emit('customers.update', sessionStorage.token, customer, callback)
    }

    onRemove(id, callback) {
        socket.emit('customers.remove', sessionStorage.token, id, callback)
    }
}
