import moment from 'moment'

export default () => {
    return JSON.parse(JSON.stringify(
        {
            name: 'Nieuwe personeelskostenstaffel',
            dates: [{
                startDate: moment().format('YYYY-MM-DD'),
                age: {
                    18: [{salary: 0, factor: 1}],
                    19: [{salary: 0, factor: 1}],
                    20: [{salary: 0, factor: 1}],
                    21: [
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1}
                    ],
                    45: [
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1}
                    ],
                    50: [
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1}
                    ],
                    55: [
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1}
                    ],
                    60: [
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1},
                        {salary: 0, factor: 1}
                    ]
                },
                aow: [
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1},
                    {salary: 0, factor: 1}
                ],
                zzpSalary: 0
            }]
        }
    ))
}
