import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import moment from 'moment'
import _ from 'underscore'

import UserStore from '../stores/UserStore.js'
import UserActions from '../actions/UserActions.js'
import CustomerStore from '../stores/CustomerStore.js'
import SettingsStore from '../stores/SettingsStore.js'

import {Button, Form, Table} from 'react-bootstrap'
import {Colors, Popup, SearchBar} from '../components/UI/index.js'
import UserModal from '../components/users/UserModal.js'


class Users extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [UserStore, CustomerStore, SettingsStore]
    }

    columns() {
        const {salaryTables} = this.state

        return [
            {title: 'SmartRoute', visible: true, key: 'synced', render: (user) => {
                return (
                    <i style={{fontSize: 12, color: user.synced ? Colors.success: Colors.danger}} className="mdi mdi-circle"/>
                )
            }},
            {title: 'Actief', visible: true, key: 'active', render: (user) => user.active? 'Ja' : 'Nee'},
            {title: 'Type', key: 'type', render: (user) => user.type},
            {title: 'Naam', key: 'name', render: (user) => user.name},
            {title: 'Geboortedatum', key: 'dateOfBirth', render: (user) => moment(user.dateOfBirth).format('DD-MM-YYYY')},
            {title: 'Datum in dienst', key: 'dateOfEmployment', render: (user) => moment(user.dateOfEmployment).format('DD-MM-YYYY')},
            {title: 'Email', key: 'email', render: (user) => user.email},
            {title: 'Staffel', key: 'salaryTable', render: (user) => salaryTables[user.salaryTable]?.name || ''}
        ]
    }

    onClickNew() {
        this.userModal.open()
        UserActions.setSelected([])
    }

    onClickEdit(user) {
        this.userModal.open(user)
        UserActions.setSelected([])
    }

    onClickSelectAll() {
        const {users, selectedUsers} = this.state

        if (users.length === selectedUsers.length) {
            UserActions.setSelected([])
        } else {
            UserActions.setSelected(_.pluck(users, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedUsers = JSON.parse(JSON.stringify(this.state.selectedUsers))
        const index = selectedUsers.indexOf(id)

        if (index === -1) {
            selectedUsers.push(id)
        } else {
            selectedUsers.splice(index, 1)
        }

        UserActions.setSelected(selectedUsers)
    }

    render() {
        const {users, filteredUsers, selectedUsers, userQueries, usersSortKey, usersSortReverse, customers, salaryTables} = this.state
        const {user} = this.props

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <SearchBar
                        columns={this.columns()}
                        queries={userQueries || []}
                        onChange={UserActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        <Button style={{marginLeft: 10}} onClick={this.onClickNew.bind(this)}>Nieuwe Gebruiker</Button>
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={users.length && selectedUsers.length === users.length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {this.columns().map((column) => {
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => UserActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === usersSortKey && usersSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === usersSortKey && !usersSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {_.values(filteredUsers).map((user) => {
                                return (
                                    <tr key={user._id}>

                                        <td>
                                            <Form.Check
                                                checked={selectedUsers.indexOf(user._id) > -1}
                                                onChange={this.onClickSelect.bind(this, user._id)}
                                            />
                                        </td>
                                        {this.columns().map((column) => {
                                            return <td key={column.key}>{column.render(user)}</td>
                                        })}
                                        <td>
                                            <Button size='sm' onClick={this.onClickEdit.bind(this, user)}>
                                                <i className='mdi mdi-pencil'/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                <UserModal
                    activeUser={user}
                    users={users}
                    customers={customers}
                    salaryTables={salaryTables}
                    ref={(ref) => this.userModal = ref}
                />

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Radium(Users)
