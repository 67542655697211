import _ from 'underscore'
import moment from 'moment'

function filter(object, queries) {
    return _.every(queries, (query) => {
        const values = query.text.split(',')

        if (query.key) {
            if (query.operator === '=') {
                return _.some(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase().indexOf(value) > -1
                })
            } else if (query.operator === '!=') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase().indexOf(value) === -1
                })
            } else if (query.operator === '<') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase() < value
                })
            } else if (query.operator === '>') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase() > value
                })
            }
        } else {
            return _.some(values, (value) => {
                value = value.trim().toLowerCase()
                return JSON.stringify(object).toString().toLowerCase().indexOf(value) > -1
            })
        }
    })
}

export default {
    customers: (customers, queries) => {
        const filteredCustomers = {}

        _.values(customers).map((customer) => {
            const clonedCustomer = JSON.parse(JSON.stringify(customer))

            clonedCustomer.active = clonedCustomer.active ? 'Ja' : 'Nee'

            if (filter(clonedCustomer, queries)) {
                filteredCustomers[customer._id] = customer
            }
        })

        return filteredCustomers
    },
    stops: (stops, queries) => {
        return stops.filter((stop) => {
            stop = structuredClone(stop)

            stop.date = moment(stop.date).format('DD-MM-YYYY')

            return filter(stop, queries)
        })
    },
    shifts: (shifts, queries) => {
        return shifts.filter((shift) => {
            shift = JSON.parse(JSON.stringify(shift))

            shift.date = moment(shift.date).format('DD-MM-YYYY')
            const hours = parseInt(moment.duration(shift.workedTime, 'seconds').asHours())
            const minutes = `0${moment.duration(shift.workedTime, 'seconds').minutes()}`.slice(-2)
            shift.workedTime = `${hours}:${minutes}`
            shift.performance = `${shift.performance} %`

            return filter(shift, queries)
        })
    },
    users: (users, queries) => {
        const filteredUsers = {}

        _.values(users).map((user) => {
            const clonedUser = JSON.parse(JSON.stringify(user))

            clonedUser.active = clonedUser.active ? 'Ja' : 'Nee'

            if (filter(clonedUser, queries)) {
                filteredUsers[user._id] = user
            }
        })

        return filteredUsers
    }
}


/**
* Traverse an object the find the value for the given multilevel key
* @param {object} obj
* @param {string} key multilevel key seperated by dots
* @return {string}
*/
function traverse(obj, key) {
    if (key) {
        return key.split('.').reduce((cur, key) => {
            if (cur && cur[key]) {
                return cur[key]
            } else {
                return ''
            }
        }, obj)
    } else {
        return ''
    }
}
