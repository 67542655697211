import React from 'react'
import Radium from 'radium'

import DeliveryTable from './DeliveryTable.js'

import {Alert, Button, Modal} from 'react-bootstrap'
import Spinner from 'react-spinkit'


class EditRouteModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            shift: null,
            index: null,
            modalIsOpen: false,
            loading: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(route) {
        this.setState({
            modalIsOpen: true,
            route: JSON.parse(JSON.stringify(route)),
            loading: false,
            error: ''
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onClickSubmit() {
        const {route} = this.state
        const {onChange} = this.props

        this.setState({loading: true, error: ''})

        onChange(route, (err, shift) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.close()
            }
        })
    }

    render() {
        const {modalIsOpen, route, loading, error} = this.state

        if (modalIsOpen && route) {
            return (
                <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Bewerk bezorgronde
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <DeliveryTable
                            route={route}
                            onChange={(route) => this.setState({route})}
                        />

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.onClickSubmit.bind(this)} disabled={loading}>
                            {loading ? <Spinner fadeIn='none'/> : 'Opslaan'}
                        </Button>
                        <Button variant='secondary' onClick={this.close.bind(this)}>Annuleren</Button>
                    </Modal.Footer>
                </Modal>
            )
        } else {
            return <div/>
        }
    }
}

export default Radium(EditRouteModal)
